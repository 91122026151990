import { Box, Stack, Typography } from "@mui/material";
import { ActiveContractCard } from "./ActiveContractCard";
import { PendingContractCard } from "./PendingContractCard";
import { CompletedContractCard } from "./CompletedContractCard";
import { IContract } from "@synota-io/synota-shared-ui";

export type ContractCardComponent =
  | typeof ActiveContractCard
  | typeof PendingContractCard
  | typeof CompletedContractCard;

type Props = {
  title: string;
  contracts: IContract[];
  as: ContractCardComponent;
};

export const ContractsList = ({ title, contracts, as: Component }: Props) => {
  return (
    <Box>
      <Typography variant="h5">
        {title} ({contracts ? contracts.length : null})
      </Typography>
      <Stack mt={6} spacing={2}>
        {contracts &&
          contracts.map((contract) => <Component key={contract.uuid} contract={contract} />)}
      </Stack>
    </Box>
  );
};
