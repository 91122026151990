import { Stack, Typography } from "@mui/material";
import { ChangeLogTable } from "../tables/ChangeLogTable";

export const ChangeLogPage = () => {
  return (
    <Stack gap={6}>
      <Typography variant="h5">Changelog</Typography>
      <ChangeLogTable />
    </Stack>
  );
};
