import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from "@mui/material";
import {
  ButtonRow,
  ErrorOnlyField,
  Form,
  FormButton,
  FormStep,
  FormSubmit,
  InputField,
  Modal,
  SearchField,
  ToggleField,
  UseModalProps,
  setControlFieldErrors,
  useAccount,
  useCountryStates,
  useCurrencyConverter,
  useCurrencyConverterMutations,
  useToast,
} from "@synota-io/synota-shared-ui";
import { useForm } from "react-hook-form";
import * as yup from "yup";

const ERR_ROUTING_MSG = "Invalid routing number";
const ERR_ACCOUNT_MSG = "Invalid account number";

const ACCOUNT_TYPES = [
  { value: "CHECKING", label: "Checking" },
  { value: "SAVINGS", label: "Savings" },
];

const DEFAULT_VALUES: AchFormValues = {
  account_number: "",
  account_number2: "",
  routing_number: "",
  additional_info: {
    address: "",
    city: "",
    company_name: "",
    postal_code: "",
    account_type: ACCOUNT_TYPES[0],
    state: null,
  },
};

interface AchFormValues {
  routing_number: string;
  account_number: string;
  account_number2: string;
  additional_info: {
    company_name: string;
    state: { label?: string; value: string } | null;
    city: string;
    postal_code: string;
    address: string;
    account_type: { label?: string; value: string };
  };
}

const schema = yup.object({
  routing_number: yup.string().required("Routing number is required!").typeError(ERR_ROUTING_MSG),
  account_number: yup.string().required("Account number is required!").typeError(ERR_ACCOUNT_MSG),
  account_number2: yup
    .string()
    .required("Account number is required!")
    .typeError(ERR_ACCOUNT_MSG)
    .oneOf([yup.ref("account_number")], "Account numbers must match"),
  additional_info: yup.object({
    company_name: yup.string().required("Company name is required"),
    state: yup
      .object({
        label: yup.string(),
        value: yup.string(),
      })
      .required("State is required"),
    city: yup.string().required("City is required"),
    postal_code: yup.string().required("Postal code is required"),
    address: yup.string().required("Address is required"),
    account_type: yup
      .object({
        value: yup.string().oneOf(["CHECKING", "SAVINGS"]),
        label: yup.string(),
      })
      .required("Account type is required"),
  }),
});

export const CurrencyConverterAchFormModal = ({ open, onClose }: UseModalProps) => {
  const toast = useToast();

  const { jwt, isAdmin } = useAccount();
  const { configurations, refetch } = useCurrencyConverter();
  const { setAchInfo, isPending } = useCurrencyConverterMutations();

  const states = useCountryStates();

  const form = useForm<AchFormValues>({
    resolver: yupResolver(schema) as any,
    defaultValues: DEFAULT_VALUES,
    values: configurations?.BluePenguinConfiguration
      ? {
          account_number: configurations?.BluePenguinConfiguration.ach_account_number || "",
          routing_number: configurations?.BluePenguinConfiguration.ach_routing_number || "",
          account_number2: configurations?.BluePenguinConfiguration.ach_account_number || "",
          additional_info: {
            account_type:
              ACCOUNT_TYPES.find(
                (a) =>
                  a.value === configurations.BluePenguinConfiguration.additional_info?.account_type,
              ) || ACCOUNT_TYPES[0],
            address: configurations.BluePenguinConfiguration.additional_info?.address || "",
            city: configurations.BluePenguinConfiguration.additional_info?.city || "",
            company_name:
              configurations.BluePenguinConfiguration.additional_info?.company_name || "",
            postal_code: configurations.BluePenguinConfiguration.additional_info?.postal_code || "",
            state:
              states.getSelectedOption(
                configurations.BluePenguinConfiguration.additional_info?.state,
              ) || null,
          },
        }
      : undefined,
  });

  if (!isAdmin) {
    return;
  }

  const onSubmit = form.handleSubmit(({ routing_number, account_number, additional_info }) => {
    setAchInfo(
      {
        jwt,
        routing_number,
        account_number,
        additional_info: {
          ...additional_info,
          state: additional_info.state?.value || "",
          account_type: additional_info.account_type?.value || "CHECKING",
          country_code: "US",
        },
      },
      {
        onSuccess: ({ data }) => {
          if (data.success) {
            refetch();
            toast.success("Successfully updated ACH information");
            return onClose();
          }

          setControlFieldErrors(form.control, data.field_errors);
        },
      },
    );
  });

  return (
    <Modal
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      title="Update ACH Information"
      actions={
        <ButtonRow width="100%">
          <FormButton
            variant="text"
            color="error"
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </FormButton>
          <Box flexGrow={1} display="flex">
            <FormSubmit
              disabled={!form.formState.isDirty}
              isLoading={isPending}
              form="set-ach-form"
              fullWidth
            >
              Submit
            </FormSubmit>
          </Box>
        </ButtonRow>
      }
    >
      <Form key={0} id="set-ach-form" onSubmit={onSubmit}>
        <FormStep>
          <InputField
            size="small"
            disabled={isPending}
            header="Company Name"
            name="additional_info.company_name"
            control={form.control}
            fullWidth
          />
          <InputField
            size="small"
            disabled={isPending}
            header="Address"
            name="additional_info.address"
            control={form.control}
            fullWidth
          />
          <ButtonRow flexWrap={{ xs: "wrap", sm: "nowrap" }}>
            <InputField
              size="small"
              disabled={isPending}
              header="City"
              name="additional_info.city"
              control={form.control}
              fullWidth
            />
            <Box>
              <SearchField
                size="small"
                header="State"
                name="additional_info.state"
                control={form.control}
                fullWidth
                ListboxProps={{ sx: { maxHeight: "18em" } }}
                placeholder="Select State"
                disabled={isPending || states.isLoading}
                options={states.options}
              />
            </Box>
            <Box width={{ xs: "100%", sm: "initial" }}>
              <InputField
                size="small"
                disabled={isPending}
                header="Postal Code"
                name="additional_info.postal_code"
                control={form.control}
                fullWidth
              />
            </Box>
          </ButtonRow>
          <ButtonRow>
            <InputField
              size="small"
              disabled={isPending}
              placeholder={configurations?.BluePenguinConfiguration?.ach_routing_number}
              header="ACH Routing Number"
              name="routing_number"
              control={form.control}
              fullWidth
            />
            <ToggleField
              fullWidth
              size="small"
              header="Account Type"
              name="additional_info.account_type"
              control={form.control}
              options={ACCOUNT_TYPES}
            />
          </ButtonRow>
          <ButtonRow>
            <InputField
              size="small"
              disabled={isPending}
              header="ACH Account Number"
              name="account_number"
              control={form.control}
              fullWidth
            />
            <InputField
              size="small"
              disabled={isPending}
              header="Repeat Account Number"
              name="account_number2"
              control={form.control}
              fullWidth
            />
          </ButtonRow>
          <ErrorOnlyField control={form.control} name="additional_info" />
        </FormStep>
      </Form>
    </Modal>
  );
};
