import { useAccount } from "@synota-io/synota-shared-ui";
import { CurrentNodeBalanceSummary } from "./CurrentNodeBalanceSummary";
import { CurrentUsdBalanceSummary } from "./CurrentUsdBalanceSummary";

export const BalanceSummary = () => {
  const { hasLightningNode, isLoading } = useAccount();

  if (isLoading) {
    return null;
  }

  if (hasLightningNode) {
    return <CurrentNodeBalanceSummary />;
  }

  return <CurrentUsdBalanceSummary />;
};
