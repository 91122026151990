import { Box, CardContent, CardProps, Stack, Typography } from "@mui/material";
import { LOCATION_NAME } from "../../../constants";
import { Link } from "react-router-dom";
import {
  CONTRACT_STATUS_COLOR_MAP,
  CounterLabel,
  EContractState,
  StatsBox,
  StatsBoxProps,
  useContracts,
} from "@synota-io/synota-shared-ui";
import { StatCard } from "./StatCard";
import { CONTRACTS_PATH } from "../../../paths";

interface Props {
  variant?: StatsBoxProps["variant"];
}

export const LocationSummary = ({
  variant = "primary",
  ...props
}: Props & Omit<CardProps, "variant">) => {
  const { contractsByState } = useContracts();

  const contracts = Object.keys(EContractState)
    .map((state) => ({
      state,
      length: contractsByState(state as EContractState).length,
    }))
    .filter(({ length, state }) => length > 0 || state === EContractState.Active);

  return (
    <StatCard {...props}>
      <CardContent>
        <StatsBox
          flexWrap="wrap"
          flexGrow={1}
          disablePadding
          variant={variant}
          component={Link}
          to={CONTRACTS_PATH}
          title={LOCATION_NAME}
          items={contracts.map(({ state, length }) => {
            return (
              <Stack key={state} direction="row" maxWidth="9em" alignItems="center">
                <Typography key={state} noWrap variant="inherit">
                  {state}
                </Typography>
                <Box flexGrow={1} />
                <CounterLabel
                  variant="inherit"
                  alignSelf="end"
                  justifySelf="end"
                  ml={2}
                  status={CONTRACT_STATUS_COLOR_MAP[state as EContractState]}
                >
                  {length}
                </CounterLabel>
              </Stack>
            );
          })}
        />
      </CardContent>
    </StatCard>
  );
};
