import { Avatar, Typography, Stack, StackProps } from "@mui/material";
import foremanLogo from "../../../../assets/vendors/foreman-circle.svg";
import { MiscellaneousServices } from "@mui/icons-material";
import { ComponentType, PropsWithChildren, ReactNode } from "react";

type Props = {
  title: string;
  caption?: ReactNode;
} & (
  | {
      src: string;
      icon?: undefined;
    }
  | {
      src?: undefined;
      icon: ReactNode;
    }
);

const ProviderAvatar = ({ src, icon, title, caption, ...props }: Props & StackProps) => {
  const sx = { width: 60, height: 60 };
  return (
    <Stack direction="row" alignItems="center" gap={2} {...props}>
      {src || icon ? (
        <>
          {src && (
            <Avatar
              sx={(theme) => ({
                ...sx,
                bgcolor: theme.palette.background.default,
              })}
              src={src}
              alt={title}
            />
          )}
          {icon && (
            <Avatar
              sx={(theme) => ({
                ...sx,
                bgcolor: theme.palette.warning.main,
                color: theme.palette.warning.contrastText,
              })}
              alt={title}
            >
              {icon}
            </Avatar>
          )}
        </>
      ) : (
        <Avatar sx={sx} alt={title} />
      )}
      <Stack pl={1}>
        <Typography variant="h5">{title}</Typography>
        {caption ? <Typography variant="subtitle2">{caption}</Typography> : null}
      </Stack>
    </Stack>
  );
};

export const ForemanAvatar = ({ children }: PropsWithChildren) => {
  return <ProviderAvatar caption={children} src={foremanLogo} title="Foreman" />;
};

export const OtherAvatar = ({ children }: PropsWithChildren) => {
  return (
    <ProviderAvatar
      caption={children}
      icon={<MiscellaneousServices fontSize="large" />}
      title="Other Software"
    />
  );
};

export const PROVIDER_AVATAR_MAP: Record<string, ComponentType<PropsWithChildren>> = {
  foreman: ForemanAvatar,
  other: OtherAvatar,
};
