import { CardContent, Stack } from "@mui/material";
import { StatsBox, formatDollarAmount, useCurrentUsdBalance } from "@synota-io/synota-shared-ui";
import { LocationSummary } from "./LocationSummary";
import { StatCard } from "./StatCard";
import { Link } from "react-router-dom";
import { SETTINGS_PATH } from "../../../paths";

export const CurrentUsdBalanceSummary = () => {
  const { balance, description } = useCurrentUsdBalance();

  return (
    <Stack direction="row" gap={6} flexGrow={1} flexWrap="wrap">
      {balance ? (
        <StatCard sx={{ height: "unset" }}>
          <CardContent>
            <StatsBox
              component={Link}
              to={SETTINGS_PATH}
              flexGrow={1}
              variant="primary"
              disablePadding
              title="Balance"
              items={[formatDollarAmount(balance)]}
              description={description}
            />
          </CardContent>
        </StatCard>
      ) : null}
      <Stack flexGrow={1} maxWidth="50%">
        <LocationSummary />
      </Stack>
    </Stack>
  );
};
