import { Card, CardContent, Grid } from "@mui/material";
import { GenerateAddressForm } from "../forms/GenerateAddressForm";
import { AutomaticPaymentFrequencyForm } from "../forms/AutomaticPaymentFrequencyForm";
import { AutomaticPaymentAddressesForm } from "../forms/AutomaticPaymentAddressesForm";
import { ConsumerPaymentMethodForm } from "../forms/ConsumerPaymentMethodForm";
import { useAccount } from "@synota-io/synota-shared-ui";

export const ConsumerSettings = () => {
  const { hasLightningNode } = useAccount();

  return (
    <Grid container spacing={6}>
      {hasLightningNode ? (
        <>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <GenerateAddressForm />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <AutomaticPaymentFrequencyForm mode="btc" />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <AutomaticPaymentAddressesForm />
              </CardContent>
            </Card>
          </Grid>
        </>
      ) : (
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <ConsumerPaymentMethodForm />
            </CardContent>
          </Card>
        </Grid>
      )}
    </Grid>
  );
};
