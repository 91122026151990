import { Chip, SvgIconTypeMap, Tooltip, Typography } from "@mui/material";
import { UserRole } from "@synota-io/synota-shared-ui";
import { AccessTime, Person, VerifiedUser } from "@mui/icons-material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { USER_ROLE_USER_FRIENDLY_NAME, UserGroupTableRowProps } from "./UserGroupTable";

const USER_ROLE_ICON_MAP: Record<UserRole, OverridableComponent<SvgIconTypeMap>> = {
  admin: VerifiedUser,
  backup_admin: VerifiedUser,
  pending_admin: AccessTime,
  pending_admin_needs_tos: VerifiedUser,
  pending_backup_admin: AccessTime,
  pending_readonly: AccessTime,
  readonly: Person,
};

export const UserRoleRow = ({ user }: UserGroupTableRowProps) => {
  const Icon = USER_ROLE_ICON_MAP[user.role];
  return (
    <Tooltip placement="right" title={user.disabled ? "Disabled" : user.status}>
      <Typography variant="subtitle4">
        <Chip
          color={!user.disabled && user.status === "Active" ? "success" : "default"}
          size="small"
          label={USER_ROLE_USER_FRIENDLY_NAME[user.role]}
          icon={<Icon />}
        />
      </Typography>
    </Tooltip>
  );
};
