import { Box, Card, CardContent, Container } from "@mui/material";
import { SynotaLogo } from "@synota-io/synota-shared-ui";
import { Outlet } from "react-router-dom";

export const AccountOutlet = () => {
  return (
    <Container maxWidth="sm">
      <Box
        padding={8}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignContent="center"
        height="100%"
      >
        <Box textAlign="center">
          <SynotaLogo pb={8} />
        </Box>
        <Box mb={12}>
          <Card>
            <CardContent sx={{ mx: 1 }}>
              <Outlet />
            </CardContent>
          </Card>
        </Box>
      </Box>
    </Container>
  );
};
