import { Box } from "@mui/material";
import {
  ButtonRow,
  ConfirmationModal,
  DateField,
  Dayjs,
  Form,
  FormButton,
  FormStep,
  FormSubmit,
  IContract,
  InputField,
  Modal,
  NumericField,
  UseModalProps,
  dayjs,
  formatDollarAmount,
  isDayjs,
  useAccount,
  useConfirmationModal,
  useCreditsMutations,
} from "@synota-io/synota-shared-ui";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useState } from "react";

export interface CreateCreditFormValues {
  contract: { uuid: string; name: string; label: string; value: number } | null;
  usdAmount: number;
  effective_date: Dayjs | null;
  credit_memo_number: string;
  service_start_time: Dayjs | null;
  service_end_time: Dayjs | null;
  title: string;
  description: string;
}

export const createInvoiceSchema = yup.object({
  contract: yup
    .object({
      value: yup.number(),
      label: yup.string(),
      uuid: yup.string().required("Invalid contract"),
      name: yup.string(),
    })
    .required("Contract is required"),
  usdAmount: yup
    .number()
    .typeError("USD Amount is required")
    .min(0, "USD Amount is required")
    .required("USD Amount is required"),
  credit_memo_number: yup.string(),
  effective_date: yup.object().required("Effective date is required"),
  service_start_time: yup.object().required("Period start is required"),
  service_end_time: yup.object().required("Period end is required"),
  description: yup.string(),
  title: yup.string().required("A title is required"),
});

export const CreateCreditFormModal = ({
  contract,
  open,
  onClose,
  onSuccess,
}: UseModalProps & { onSuccess: () => void; contract: IContract }) => {
  const { jwt } = useAccount();
  const { isPending, addCredit } = useCreditsMutations();

  const [hasSetEndDate, setHasSetEndDate] = useState(false);

  const { control, handleSubmit, watch, setValue, trigger } = useForm<CreateCreditFormValues>({
    resolver: yupResolver(createInvoiceSchema) as any,
    defaultValues: {
      contract: { label: contract.name, value: contract.id, ...contract },
      usdAmount: "" as unknown as number,
      credit_memo_number: "",
      effective_date: dayjs(),
      service_start_time: dayjs(),
      service_end_time: dayjs(),
      description: "",
      title: "",
    },
  });

  const values = watch();

  const confirmModal = useConfirmationModal();

  const onSubmit = handleSubmit(
    ({ usdAmount, contract, effective_date, service_start_time, service_end_time, ...values }) => {
      if (!contract) {
        return;
      }
      confirmModal.onOpen({
        title: `Create a credit of ${formatDollarAmount(usdAmount)} for contract "${contract.name}"?`,
        onConfirm() {
          confirmModal.onClose();
          addCredit(
            {
              jwt,
              amount: String(usdAmount),
              contract_shared_uuid: contract.uuid,
              effective_date: (effective_date as Dayjs).toISOString(),
              service_start_time: (service_start_time as Dayjs).toISOString(),
              service_end_time: (service_end_time as Dayjs).toISOString(),
              ...values,
            },
            {
              onSuccess: () => {
                onSuccess();
                onClose();
              },
            },
          );
        },
      });
    },
  );

  return (
    <>
      <Modal
        maxWidth="sm"
        fullWidth
        open={open}
        onClose={onClose}
        title={`Add credit for "${contract.name}"`}
        actions={
          <ButtonRow width="100%">
            <FormButton
              variant="text"
              color="error"
              onClick={() => {
                onClose();
              }}
            >
              Cancel
            </FormButton>
            <Box flexGrow={1} display="flex">
              <FormSubmit isLoading={isPending} form="create-credit-form" fullWidth />
            </Box>
          </ButtonRow>
        }
      >
        <Form id="create-credit-form" onSubmit={onSubmit}>
          <FormStep>
            <InputField
              size="small"
              header="Header/Title"
              autoFocus
              fullWidth
              control={control}
              name="title"
            />

            <ButtonRow>
              <DateField
                header="Service Date(s)"
                size="small"
                label="Start date"
                fullWidth
                maxDate={isDayjs(values.service_end_time) ? values.service_end_time : undefined}
                control={control}
                name="service_start_time"
                onChange={(value) => {
                  if (value && !hasSetEndDate) {
                    setValue("service_end_time", value);
                    trigger("service_end_time");
                  }
                }}
              />
              <DateField
                fullWidth
                header="&nbsp;"
                size="small"
                minDate={isDayjs(values.service_start_time) ? values.service_start_time : undefined}
                label="End date"
                onChange={() => {
                  setHasSetEndDate(true);
                }}
                control={control}
                name="service_end_time"
              />
            </ButtonRow>
            <ButtonRow>
              <NumericField
                size="small"
                header="Amount"
                fullWidth
                autoComplete="off"
                InputProps={{ endAdornment: "$" }}
                control={control}
                name="usdAmount"
              />
              <DateField
                size="small"
                header="Effective Date"
                fullWidth
                control={control}
                name="effective_date"
              />
            </ButtonRow>
            <InputField
              size="small"
              header="Credit Memo #"
              fullWidth
              autoComplete="off"
              control={control}
              name="credit_memo_number"
            />
            <InputField
              size="small"
              header="Description"
              fullWidth
              control={control}
              name="description"
            />
          </FormStep>
        </Form>
      </Modal>
      {confirmModal.open && <ConfirmationModal {...confirmModal} />}
    </>
  );
};
