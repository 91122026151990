import { IContract, useAccount } from "@synota-io/synota-shared-ui";
import { ConsumerMetricsTable } from "./ConsumerMetricsTable";
import { SupplierMetricsTable } from "./SupplierMetricsTable";

interface Props {
  contract?: IContract | null;
}

export const MetricsTable = ({ contract }: Props) => {
  const { isConsumer, isSupplier } = useAccount();

  if (isConsumer) {
    return <ConsumerMetricsTable contract={contract} />;
  }

  if (isSupplier) {
    return <SupplierMetricsTable contract={contract} />;
  }

  return null;
};
