import { Outlet } from "react-router-dom";
import { LoadingPage } from "../../shared/pages/LoadingPage";

export const SettingsOutlet = () => {
  return (
    <LoadingPage>
      <Outlet />
    </LoadingPage>
  );
};
