import { Typography, TypographyProps } from "@mui/material";
import {
  DetailsListItem,
  IContract,
  formatDollarAmount,
  getBitcoinFromSats,
  useBitcoinRate,
} from "@synota-io/synota-shared-ui";
import { PropsWithChildren } from "react";

type Props = { contract: IContract | null };

export const ReserveAmountHeld = ({ contract }: Props) => {
  if (
    !contract ||
    (!Number(contract.reserveRequirementsInSats) && !Number(contract.reserveRequirementsInUsd))
  ) {
    return null;
  }

  const hasBothRequirements = Boolean(
    contract.reserveRequirementsInSats && contract.reserveRequirementsInUsd,
  );

  return (
    <>
      <DetailsListItem title="Reserve Requirement">
        {contract.reserveAllocation ? (
          <>
            {contract.reserveRequirementsInUsd ? (
              <Amount>{formatDollarAmount(contract.reserveRequirementsInUsd)}</Amount>
            ) : null}

            {hasBothRequirements && <Amount color="text.secondary"> + </Amount>}

            {contract.reserveRequirementsInSats ? (
              <Amount>{getBitcoinFromSats(contract.reserveRequirementsInSats)} BTC</Amount>
            ) : null}
          </>
        ) : (
          "N/A"
        )}
      </DetailsListItem>
      <DetailsListItem title="Current Reserve Held">
        {contract.reserveAllocation ? (
          <>
            {hasBothRequirements ? (
              <ReserveAllocationRequiredInBoth allocation={contract.reserveAllocation} />
            ) : (
              <>
                {contract.reserveRequirementsInSats ? (
                  <ReserveAllocationRequiredInSats allocation={contract.reserveAllocation} />
                ) : null}

                {contract.reserveRequirementsInUsd ? (
                  <ReserveAllocationRequiredInUsd allocation={contract.reserveAllocation} />
                ) : null}
              </>
            )}
          </>
        ) : (
          "N/A"
        )}
      </DetailsListItem>
    </>
  );
};

const ReserveAllocationRequiredInUsd = ({
  allocation,
}: {
  allocation: Required<IContract>["reserveAllocation"];
}) => {
  const { getDollars } = useBitcoinRate();
  if (allocation.usd) {
    return <Amount>{formatDollarAmount(allocation.usd)}</Amount>;
  }

  return (
    <>
      <Amount>{getDollars(getBitcoinFromSats(allocation.sats))}</Amount>
      <Amount color="text.secondary"> ({getBitcoinFromSats(allocation.sats)} BTC)</Amount>
    </>
  );
};

const ReserveAllocationRequiredInSats = ({
  allocation,
}: {
  allocation: Required<IContract>["reserveAllocation"];
}) => {
  const { getBitcoin } = useBitcoinRate();

  if (allocation.usd) {
    return (
      <>
        <Amount>{getBitcoin(allocation.usd)} BTC</Amount>
        <Amount color="text.secondary"> ({formatDollarAmount(allocation.usd)})</Amount>
      </>
    );
  }

  return <Amount>{getBitcoinFromSats(allocation.sats)} BTC</Amount>;
};

const ReserveAllocationRequiredInBoth = ({
  allocation,
}: {
  allocation: Required<IContract>["reserveAllocation"];
}) => {
  const { getBitcoin, getDollars } = useBitcoinRate();

  if (allocation.usd) {
    return (
      <>
        <Amount>{formatDollarAmount(allocation.usd)}</Amount>
        <Amount color="text.secondary"> ({getBitcoin(allocation.usd)} BTC)</Amount>
      </>
    );
  }

  return (
    <>
      <Amount>{getBitcoinFromSats(allocation.sats)} BTC</Amount>
      <Amount color="text.secondary">
        {" "}
        ({getDollars(getBitcoinFromSats(allocation.sats))} USD)
      </Amount>
    </>
  );
};

const Amount = ({ children, ...props }: PropsWithChildren<Pick<TypographyProps, "color">>) => {
  return (
    <Typography variant="body4" display="inline" {...props}>
      {children}
    </Typography>
  );
};
