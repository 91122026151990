import { Card, CardContent, Stack } from "@mui/material";
import { IContract } from "@synota-io/synota-shared-ui";
import { MinerManagementSummary } from "./views/MinerManagementSummary";

interface Props {
  contract: IContract;
}

export const MinerManagement = ({ contract }: Props) => {
  return (
    <Card sx={{ flexGrow: 1 }}>
      <CardContent sx={{ height: "100%" }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="start"
          gap={6}
          width="100%"
          height="100%"
        >
          <MinerManagementSummary contract={contract} />
        </Stack>
      </CardContent>
    </Card>
  );
};
