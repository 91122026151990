import { useAccount } from "@synota-io/synota-shared-ui";
import { Navigate, Outlet } from "react-router-dom";
import { LoadingPage } from "../../shared/pages/LoadingPage";

export const ChargesOutlet = () => {
  const { email, supportsAdditionalCharges, isFetching, isReady } = useAccount();

  if (
    // Ideally supportsAdditionalCharges should be false when the
    // account is loaded so that we dont need to check for "email"
    !isFetching &&
    isReady &&
    email &&
    !supportsAdditionalCharges
  ) {
    return <Navigate to="/" />;
  }

  return (
    <LoadingPage>
      <Outlet />
    </LoadingPage>
  );
};
