import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from "@mui/material";
import {
  ButtonRow,
  Form,
  FormButton,
  FormStep,
  FormSubmit,
  InputField,
  Modal,
  UseModalProps,
} from "@synota-io/synota-shared-ui";
import { useForm } from "react-hook-form";
import * as yup from "yup";

const schema = yup.object({
  reason: yup.string().required("A reason is required to reject this charge"),
});

export const RejectChargeModal = ({
  open,
  onClose,
  onConfirm,
  isLoading,
}: UseModalProps & {
  isLoading: boolean;
  onConfirm: ({ reason }: { reason: string }) => void;
}) => {
  const { control, handleSubmit } = useForm({
    defaultValues: { reason: "" },
    resolver: yupResolver(schema),
  });

  return (
    <Modal
      maxWidth="xs"
      fullWidth
      open={open}
      onClose={onClose}
      title="Are you sure you want to reject this invoice?"
      actions={
        <ButtonRow width="100%">
          <FormButton
            variant="text"
            color="error"
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </FormButton>
          <Box flexGrow={1} display="flex">
            <FormSubmit
              color="secondary"
              isLoading={isLoading}
              form="reject-additional-charge-form-confirm"
              fullWidth
            >
              Reject Invoice
            </FormSubmit>
          </Box>
        </ButtonRow>
      }
    >
      <Form id="reject-additional-charge-form-confirm" onSubmit={handleSubmit(onConfirm)}>
        <FormStep>
          <InputField
            fullWidth
            required
            control={control}
            name="reason"
            header="Reason of Rejection"
          />
        </FormStep>
      </Form>
    </Modal>
  );
};
