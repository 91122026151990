import * as yup from "yup";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, AlertTitle, Slide, Stack, Typography } from "@mui/material";
import {
  CheckboxField,
  Form,
  FormInlineStep,
  FormStep,
  FormSubmit,
  NumberSelectField,
  NumericField,
  SelectField,
  SetPayoutConfigurationInput,
  dayjs,
  useAccount,
  useAutomaticPayments,
  useToast,
} from "@synota-io/synota-shared-ui";
import { useState } from "react";

const FREQ_MSG = "Disbursement frequency is required";
const DAY_OF_MONTH_MSG = "Please choose a day between 1 and 28";
const AMOUNT_MSG = "Minimum $10.00 required";
const MIN_AMOUNT = 10;

export const DISBURSEMENT_FREQUENCY_OPTIONS = [
  { label: "Disabled", value: 0, caption: "USD balance remains in Strike account." },
  {
    label: "No Restrictions",
    value: 1,
    caption: "Disbursements initiated when minimum USD threshold is met.",
  },
  { label: "Daily", value: 86400, caption: "Disbursements initiated between 3 and 6PM ET." },
  {
    label: "Weekly",
    value: 604800,
    caption: "One disbursement every seven (7) days.",
  },
  {
    label: "Monthly",
    value: -1,
    caption:
      "One disbursement each month. You may select a specific day or the last day of the month. ",
  },
];

const frequencySchema: yup.ObjectSchema<FrequencyFormValues> = yup.object({
  maxFrequency: yup
    .number()
    .oneOf(DISBURSEMENT_FREQUENCY_OPTIONS.map((o) => o.value))
    .required(FREQ_MSG),
  minAmount: yup.number().min(MIN_AMOUNT, AMOUNT_MSG).required(AMOUNT_MSG).typeError(AMOUNT_MSG),
  dayOfMonth: yup
    .number()
    .min(0, DAY_OF_MONTH_MSG)
    .max(28, DAY_OF_MONTH_MSG)
    .test((value, context) => {
      return value || context.parent.lastDayOfMonth;
    }),
  lastDayOfMonth: yup.bool(),
});

export type FrequencyFormValues = {
  maxFrequency: number;
  minAmount: number;
  dayOfMonth?: number;
  lastDayOfMonth?: boolean;
};

export const AutomaticDisbursementForm = () => {
  const { payoutConfiguration, setPayoutConfiguration, isPending, refetchPayout } =
    useAutomaticPayments();
  const { jwt, isAdmin } = useAccount();
  const { success } = useToast();

  const [dayOfMonthPreviousValue, setDayOfMonthPreviousValue] = useState(1);

  const frequencyForm = useForm<FrequencyFormValues>({
    resolver: yupResolver(frequencySchema),
    mode: "all",
    values: payoutConfiguration
      ? {
          maxFrequency:
            DISBURSEMENT_FREQUENCY_OPTIONS.find(
              (option) => option.value === payoutConfiguration.payout_frequency_in_seconds,
            )?.value || DISBURSEMENT_FREQUENCY_OPTIONS[0].value,
          minAmount: payoutConfiguration.payout_minimum_in_usd || MIN_AMOUNT,
          lastDayOfMonth: payoutConfiguration.payout_day_of_month === 0,
          dayOfMonth:
            payoutConfiguration.payout_day_of_month !== null
              ? payoutConfiguration.payout_day_of_month
              : 1,
        }
      : {
          maxFrequency: DISBURSEMENT_FREQUENCY_OPTIONS[0].value,
          minAmount: MIN_AMOUNT,
          lastDayOfMonth: false,
          dayOfMonth: 1,
        },
  });

  const onFrequencySubmit = ({
    maxFrequency,
    minAmount,
    dayOfMonth,
    lastDayOfMonth,
  }: FrequencyFormValues) => {
    const input: SetPayoutConfigurationInput = {
      jwt,
      payout_frequency_in_secs: Number(maxFrequency),
      payout_window_begin: dayjs().toISOString(),
      payout_minimum_in_usd: minAmount || 0,
      payout_minimum_in_sats: 0,
      payout_day_of_month: maxFrequency === -1 ? (lastDayOfMonth ? 0 : dayOfMonth) : undefined,
    };

    setPayoutConfiguration(input, {
      onSuccess() {
        refetchPayout();
        success("Automatic disbursement saved successfully.");
      },
    });
  };

  const values = useWatch({ control: frequencyForm.control });
  const selectedFrequency = DISBURSEMENT_FREQUENCY_OPTIONS.find(
    (o) => o.value === values.maxFrequency,
  );

  return (
    <Form onSubmit={frequencyForm.handleSubmit(onFrequencySubmit)}>
      <Typography variant="h6">Automatic Disbursements Frequency</Typography>
      <FormInlineStep>
        <Stack flexGrow={1} spacing={4}>
          <SelectField
            required
            readOnly={!isAdmin}
            control={frequencyForm.control}
            header="Payment Frequency"
            name="maxFrequency"
            options={DISBURSEMENT_FREQUENCY_OPTIONS}
            variant="outlined"
          />
          <Alert severity="info">
            <AlertTitle>{selectedFrequency?.caption}</AlertTitle>
          </Alert>
        </Stack>

        <Slide in={values.maxFrequency === -1} mountOnEnter unmountOnExit direction="left">
          <Stack direction="row" alignItems="end" gap={8}>
            <NumberSelectField
              readOnly={!isAdmin}
              control={frequencyForm.control}
              name="dayOfMonth"
              range={[1, 28]}
              disabled={Boolean(values.lastDayOfMonth)}
              header="Day of Month"
            />
            <CheckboxField
              name="lastDayOfMonth"
              readOnly={!isAdmin}
              control={frequencyForm.control}
              label="Last Day Of Month"
              onChange={(e) => {
                if (e.target.checked) {
                  setDayOfMonthPreviousValue(values.dayOfMonth || 1);
                  frequencyForm.setValue("dayOfMonth", 0);
                } else {
                  frequencyForm.setValue("dayOfMonth", dayOfMonthPreviousValue);
                }
                frequencyForm.trigger("dayOfMonth");
              }}
            />
          </Stack>
        </Slide>
      </FormInlineStep>

      <FormStep in={values.maxFrequency !== 0}>
        <NumericField
          required
          fullWidth
          readOnly={!isAdmin}
          InputProps={{
            slotProps: { input: { min: MIN_AMOUNT } },
          }}
          control={frequencyForm.control}
          header="Minimum Payout Amount"
          name="minAmount"
          caption={AMOUNT_MSG}
        />
      </FormStep>

      <FormSubmit disabled={!frequencyForm.formState.isDirty || !isAdmin} isLoading={isPending} />
    </Form>
  );
};
