import { Box } from "@mui/material";
import * as yup from "yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  ButtonRow,
  Form,
  FormButton,
  FormSubmit,
  IContract,
  InputField,
  Modal,
  UseModalProps,
  useAccount,
  useRenameContract,
  useToast,
} from "@synota-io/synota-shared-ui";

export type EditContractSchema = { name: string };

type Props = UseModalProps & {
  contract: IContract;
  onSuccess(): void;
};

const schema = yup.object({
  name: yup.string().required("Contract name is required!"),
});

export const EditContractModal = ({ contract, open, onSuccess, onClose }: Props) => {
  const { jwt } = useAccount();
  const { success } = useToast();

  const { renameContract, isPending } = useRenameContract({
    onSuccess: () => {
      success("Contract name saved successfully.");
      onSuccess();
      onClose();
    },
  });

  const { handleSubmit, control } = useForm<EditContractSchema>({
    resolver: yupResolver(schema),
    values: { name: contract.name },
  });

  const onSubmit: SubmitHandler<EditContractSchema> = ({ name }) => {
    renameContract({
      jwt,
      contract_name: name,
      contract_id: contract.id,
    });
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      fullWidth
      title="Rename contract"
      actions={
        <ButtonRow width="100%">
          <FormButton
            disabled={isPending}
            variant="text"
            color="error"
            size="small"
            onClick={() => onClose()}
          >
            Cancel
          </FormButton>

          <Box flexGrow={1} display="flex">
            <FormSubmit
              isLoading={isPending}
              fullWidth
              variant="contained"
              color="secondary"
              form="edit-contract-modal-form"
            />
          </Box>
        </ButtonRow>
      }
    >
      <Form id="edit-contract-modal-form" onSubmit={handleSubmit(onSubmit)}>
        <InputField
          disabled={isPending}
          name="name"
          fullWidth
          control={control}
          label="Contract Name"
          autoComplete="off"
        />
      </Form>
    </Modal>
  );
};
