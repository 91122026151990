export const DEFAULT_LABELS = {
  contractId: "Contract ID",
  contractName: "Contract",
  invoiceNumber: "Invoice Number",
  energyDelivered: "Energy Delivered",
  invoiceAmount: "Total Invoice Amount",
  paymentReceived: "Cash Received",
  settlementStart: "Settlement Start",
  startTime: "Start Time",
  settlementEnd: "Settlement End",
  endTime: "End Time",
  energyDeliveredWithUnit: "Energy Delivered (MWh)",
  hostFeeInvoice: "Invoice Amount",
  taxPayable: "Tax Payable",
  customerPayment: "Payment Amount",
  revenue: "Revenue",
  cashReceived: "Cash Received",
  accountsReceivable: "Accounts Receivable",
  effectiveRateMwh: "Effective Rate ($/MWh)",
  effectiveRateKwh: "Effective Rate ($/kWh)",
  calculatedChargeAmount: "Calculated Charge",
};

export const MINER_LABELS = {
  ...DEFAULT_LABELS,
  paymentReceived: "Payment Sent",
  energyDelivered: "Energy Consumed",
  energyDeliveredWithUnit: "Energy Delivered (MWh)",
  taxPayable: "Synota Service Charge",
  revenue: "Expense",
  accountsReceivable: "Amount Owed",
};

export type PaymentRequestLabel = keyof typeof DEFAULT_LABELS;

export function getLabel(key: PaymentRequestLabel, IS_MINER_MODE?: boolean): string {
  if (IS_MINER_MODE) {
    return MINER_LABELS[key];
  }
  return DEFAULT_LABELS[key];
}
