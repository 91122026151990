import { Stack } from "@mui/material";
import {
  ButtonLink,
  FormButton,
  InternalServerError,
  useAccount,
} from "@synota-io/synota-shared-ui";
import { Navigate } from "react-router-dom";

export const ErrorPage = () => {
  const { jwt, error, logout } = useAccount();

  if (!error) {
    return <Navigate to="/" />;
  }

  return (
    <InternalServerError>
      <Stack pt={4} gap={4}>
        <ButtonLink variant="contained" size="small" to="/">
          Retry
        </ButtonLink>
        {jwt ? (
          <FormButton size="small" variant="text" color="error" onClick={() => logout()}>
            Logout
          </FormButton>
        ) : null}
      </Stack>
    </InternalServerError>
  );
};
