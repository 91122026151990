import * as yup from "yup";
import { BTC_IN_SATOSHI, SATOSHIS_IN_BTC } from "../../../constants";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Slide, Typography } from "@mui/material";
import {
  Form,
  FormStep,
  FormSubmit,
  NumericField,
  SelectField,
  SetPayoutConfigurationInput,
  dayjs,
  useAccount,
  useAutomaticPayments,
  useToast,
} from "@synota-io/synota-shared-ui";

const FREQ_MSG = "Withdrawal frequency is required";
const AMOUNT_MSG = "Minimum allowed is 0.005 BTC";

const FREQUENCY_OPTIONS = [
  { label: "No Automatic Withdrawals", value: 0 },
  { label: "No Restrictions", value: 1 },
  { label: "Daily", value: 86400 },
  { label: "Weekly", value: 604800 },
];

const frequencySchema = yup.object({
  maxFrequency: yup
    .number()
    .oneOf(FREQUENCY_OPTIONS.map((o) => o.value))
    .required(FREQ_MSG),
});

const btcSchema = frequencySchema.shape({
  minAmount: yup.number().min(0.005, AMOUNT_MSG).required(AMOUNT_MSG).typeError(AMOUNT_MSG),
});

const usdSchema = frequencySchema;

export type FrequencyFormValues = {
  maxFrequency: number;
  minAmount?: number;
};

interface Props {
  mode: "btc" | "usd";
}

export const AutomaticPaymentFrequencyForm = ({ mode }: Props) => {
  const { payoutConfiguration, setPayoutConfiguration, isPending, refetchPayout } =
    useAutomaticPayments();
  const { jwt, isAdmin } = useAccount();
  const { success } = useToast();

  const frequencyForm = useForm<FrequencyFormValues>({
    resolver: yupResolver(mode === "btc" ? btcSchema : usdSchema),
    values: payoutConfiguration
      ? {
          maxFrequency:
            FREQUENCY_OPTIONS.find(
              (option) => option.value === payoutConfiguration.payout_frequency_in_seconds,
            )?.value || FREQUENCY_OPTIONS[0].value,
          minAmount: payoutConfiguration.payout_minimum_in_sats * BTC_IN_SATOSHI,
        }
      : {
          maxFrequency: FREQUENCY_OPTIONS[0].value,
          minAmount: 0.005,
        },
    mode: "all",
  });

  const onFrequencySubmit = ({ maxFrequency, minAmount }: FrequencyFormValues) => {
    const input: SetPayoutConfigurationInput = {
      jwt,
      payout_frequency_in_secs: Number(maxFrequency),
      payout_minimum_in_sats: (minAmount || 0) * SATOSHIS_IN_BTC,
      payout_window_begin: dayjs().toISOString(),
    };

    setPayoutConfiguration(input, {
      onSuccess() {
        refetchPayout();
        success("Automatic withdrawal frequency saved successfully.");
      },
    });
  };

  const values = useWatch({ control: frequencyForm.control });

  const shouldShowAmountField = mode === "btc" && values.maxFrequency !== 0;

  return (
    <Form onSubmit={frequencyForm.handleSubmit(onFrequencySubmit)}>
      <Typography variant="h6">Automatic Withdrawal Frequency</Typography>
      <FormStep>
        <SelectField
          required
          readOnly={!isAdmin}
          control={frequencyForm.control}
          header="Maximum Withdrawal Frequency"
          name="maxFrequency"
          options={FREQUENCY_OPTIONS}
        />
        {shouldShowAmountField && (
          <Slide in={shouldShowAmountField} mountOnEnter unmountOnExit direction="left">
            <Box width="100%">
              <NumericField
                required
                fullWidth
                readOnly={!isAdmin}
                InputProps={{ slotProps: { input: { min: 0.005 } } }}
                control={frequencyForm.control}
                header="Minimum Withdrawal Amount"
                name="minAmount"
                caption="Minimum 0.005 BTC required"
              />
            </Box>
          </Slide>
        )}
      </FormStep>
      <FormSubmit isLoading={isPending} disabled={!frequencyForm.formState.isDirty || !isAdmin} />
    </Form>
  );
};
