import {
  Card,
  CardContent,
  Slide,
  Stack,
  Table,
  TableBody,
  TableFooter,
  TableHead,
  Typography,
  TypographyProps,
} from "@mui/material";
import { ReactNode } from "react";
import {
  AutoEllipsis,
  BodyCell,
  BodyRow,
  Cell,
  ChangeLogRow,
  FormSubmit,
  HeadCell,
  HeadRow,
  StickyTableContainer,
  formateDateTime,
  useChangeLog,
  useWindowSize,
} from "@synota-io/synota-shared-ui";

interface ChangeLogTableField {
  key: string;
  color?: TypographyProps["color"];
  value: (row: ChangeLogRow) => ReactNode;
}

export const ChangeLogTable = () => {
  useWindowSize();
  const { items, fetchNextPage, hasNextPage, isFetching } = useChangeLog();

  const tableFields: ChangeLogTableField[] = [
    {
      value: (item) => <AutoEllipsis>{formateDateTime(item.date_changed) || ""}</AutoEllipsis>,
      key: "Date of Change",
    },
    {
      value: (item) => <AutoEllipsis>{formateDateTime(item.effective_date) || ""}</AutoEllipsis>,
      key: "Effective Date",
    },
    {
      value: (item) => <AutoEllipsis>{item.action_description}</AutoEllipsis>,
      key: "Description",
    },
    {
      value: (item) => <AutoEllipsis>{item.previous_value || ""}</AutoEllipsis>,
      key: "Previous value",
    },
    {
      value: (item) => <AutoEllipsis>{item.new_value || ""}</AutoEllipsis>,
      key: "New value",
    },
    {
      value: (item) => <AutoEllipsis>{item.changed_by}</AutoEllipsis>,
      key: "Changed by",
    },
    {
      value: (item) => <AutoEllipsis>{item.contract_name || "N/A"}</AutoEllipsis>,
      key: "Contract",
    },
  ];

  return (
    <Stack gap={6}>
      <Slide in direction="up">
        <Card>
          <CardContent>
            <StickyTableContainer>
              <Table stickyHeader>
                <TableHead>
                  <HeadRow>
                    {tableFields.map((field) => (
                      <HeadCell key={field.key}>
                        <AutoEllipsis maxLine={2}>{field.key}</AutoEllipsis>
                      </HeadCell>
                    ))}
                  </HeadRow>
                </TableHead>
                <TableBody>
                  {items &&
                    items.map((item) => {
                      return (
                        <BodyRow key={item.key}>
                          {tableFields.map((field) => (
                            <Cell key={field.key}>
                              <Typography variant="subtitle4" color={field.color}>
                                {field.value(item)}
                              </Typography>
                            </Cell>
                          ))}
                        </BodyRow>
                      );
                    })}
                </TableBody>
                {!isFetching && !items.length ? (
                  <TableFooter>
                    <BodyRow>
                      <BodyCell>No changes found.</BodyCell>
                    </BodyRow>
                  </TableFooter>
                ) : null}
              </Table>
            </StickyTableContainer>
          </CardContent>
        </Card>
      </Slide>
      <Slide in direction="up">
        <Stack direction="row">
          <FormSubmit
            isLoading={isFetching}
            disabled={!hasNextPage}
            onClick={() => fetchNextPage()}
            variant="contained"
          >
            {isFetching || hasNextPage ? "Load More" : "No more results"}
          </FormSubmit>
        </Stack>
      </Slide>
    </Stack>
  );
};
