import { Box } from "@mui/material";
import {
  ButtonRow,
  Form,
  FormButton,
  FormSubmit,
  Modal,
  UseModalProps,
} from "@synota-io/synota-shared-ui";

export const VoidChargeModal = ({
  open,
  onClose,
  onConfirm,
  isLoading,
}: UseModalProps & {
  isLoading: boolean;
  onConfirm: () => void;
}) => {
  return (
    <Modal
      maxWidth="xs"
      fullWidth
      open={open}
      onClose={onClose}
      title="Are you sure you want to void this invoice?"
      actions={
        <ButtonRow width="100%">
          <FormButton
            variant="text"
            color="error"
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </FormButton>
          <Box flexGrow={1} display="flex">
            <FormSubmit
              color="secondary"
              isLoading={isLoading}
              form="void-additional-charge-form-confirm"
              fullWidth
            >
              Void Invoice
            </FormSubmit>
          </Box>
        </ButtonRow>
      }
    >
      <Form
        id="void-additional-charge-form-confirm"
        onSubmit={(e) => {
          e.preventDefault();
          onConfirm();
        }}
      />
    </Modal>
  );
};
