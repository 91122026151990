import { Box, Grid } from "@mui/material";
import { ContractCardComponent, ContractsList } from "../components/ContractsList";
import { ActiveContractCard } from "../components/ActiveContractCard";
import { PendingContractCard } from "../components/PendingContractCard";
import { CompletedContractCard } from "../components/CompletedContractCard";
import { EContractState, useContracts } from "@synota-io/synota-shared-ui";

const CONTRACT_STATUS_COMPONENT_MAP: Record<EContractState, ContractCardComponent> = {
  [EContractState.Active]: ActiveContractCard,
  [EContractState.Unsigned]: PendingContractCard,
  [EContractState.Completed]: CompletedContractCard,
  [EContractState.Finishing]: ActiveContractCard,
  [EContractState.Upcoming]: CompletedContractCard,
};

export const ContractsPage = () => {
  const { contractsByState } = useContracts();

  return (
    <Grid pt={4} container spacing={6}>
      {Object.keys(EContractState).map((state) => {
        const typedState = state as EContractState;
        const contracts = contractsByState(typedState);

        if (!contracts.length && state !== EContractState.Active) {
          return <Box key={state} />;
        }

        return (
          <Grid item xs={12} key={state}>
            <ContractsList
              title={`${state} Contracts`}
              as={CONTRACT_STATUS_COMPONENT_MAP[typedState]}
              contracts={contracts}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};
