import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Form, FormStep, FormSubmit, InputField } from "@synota-io/synota-shared-ui";
import * as yup from "yup";

const otherSchema = yup.object({
  provider: yup.string().required("Please type your desired provider"),
});

export const OtherSetupForm = () => {
  const { control, formState } = useForm({
    mode: "all",
    resolver: yupResolver(otherSchema),
  });

  return (
    <Form>
      <FormStep>
        <InputField
          size="small"
          fullWidth
          header="Request support for your miner management software"
          placeholder="Name"
          control={control}
          name="provider"
        />
        <FormSubmit size="small" disabled={!formState.isValid} fullWidth>
          Send to Synota
        </FormSubmit>
      </FormStep>
    </Form>
  );
};
