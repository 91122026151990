import { Box, Grid, Stack } from "@mui/material";
import { CashFlowChart } from "../components/CashFlowChart";
import { MetricsTable } from "../components/MetricsTable";
import { BalanceSummary } from "../components/BalanceSummary";
import { useAccount, useBreakpoint } from "@synota-io/synota-shared-ui";

export const DashboardPage = () => {
  const { hasLightningNode } = useAccount();

  const isLg = useBreakpoint("lg");

  if (!hasLightningNode && isLg) {
    return (
      <Box>
        <Grid container spacing={6}>
          <Grid item lg={6}>
            <CashFlowChart />
          </Grid>
          <Grid item lg={6}>
            <Stack gap={6}>
              <BalanceSummary />
              <MetricsTable />
            </Stack>
          </Grid>
        </Grid>
      </Box>
    );
  }

  return (
    <Box>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <BalanceSummary />
        </Grid>
        <Grid item xs={12} lg={6}>
          <CashFlowChart />
        </Grid>
        <Grid item xs={12} lg={6}>
          <Box>
            <MetricsTable />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
