import {
  IconButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Menu,
  Box,
  MenuItem,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { MouseEvent, useCallback, useState } from "react";
import {
  EXPLORER_CHANGELOG_PATH,
  SETTINGS_ALERTS_PATH,
  SETTINGS_PATH,
  SETTINGS_USERS_PATH,
} from "../../paths";
import {
  ThemeSwitch,
  useAccount,
  useClipboard,
  useLayout,
  useLogout,
} from "@synota-io/synota-shared-ui";
import {
  CheckCircle,
  Group,
  History,
  Logout,
  Notifications,
  PeopleOutline,
  PersonOutline,
  Settings,
} from "@mui/icons-material";
import { Divider } from "@mui/material";

export const ProfileMenu = () => {
  const { isLogged, switchAccount, userGroupAccounts, self } = useAccount();
  const { logout } = useLogout();
  const { setSidebarOpen, isMobile } = useLayout();
  const { copy } = useClipboard({ defaultText: self.email, toast: "Email copied to clipboard!" });

  const navigate = useNavigate();

  const [profileAnchor, setProfileAnchor] = useState<null | HTMLElement>(null);

  const onOpenProfile = (event: MouseEvent<HTMLElement>) => {
    setProfileAnchor(event.currentTarget);
  };

  const onCloseProfile = () => {
    setProfileAnchor(null);
  };

  const navigateToAlertsPage = () => {
    navigate(SETTINGS_ALERTS_PATH);
  };

  const navigateToSettingsPage = () => {
    navigate(SETTINGS_PATH);
  };

  const navigateToChangelog = () => {
    navigate(EXPLORER_CHANGELOG_PATH);
  };

  const navigateToUsers = () => {
    navigate(SETTINGS_USERS_PATH);
  };

  const onProfileItemClick = useCallback(
    (cb: () => void) => {
      setSidebarOpen(false);
      setProfileAnchor(null);
      cb();
    },
    [setSidebarOpen],
  );

  const activeUserGroupAccount = userGroupAccounts.length
    ? userGroupAccounts.find(({ isActive }) => isActive)
    : null;

  return (
    <Stack direction="row" spacing={2} position="relative">
      {isLogged && (
        <>
          <Stack
            sx={{ cursor: "pointer" }}
            alignItems="center"
            aria-controls="menu-appbar"
            onClick={onOpenProfile}
            role="button"
            direction="row"
            spacing={3}
          >
            {isMobile ? null : (
              <Stack alignItems="end">
                <Typography
                  color="text.secondary"
                  variant="button"
                  fontSize="small"
                  textTransform="none"
                >
                  {activeUserGroupAccount ? (
                    <span>{activeUserGroupAccount?.description}</span>
                  ) : (
                    self.email || "Account"
                  )}
                </Typography>
                {userGroupAccounts.length ? (
                  <Typography
                    variant="subtitle3"
                    textTransform="uppercase"
                    fontSize="small"
                    fontWeight="normal"
                  ></Typography>
                ) : null}
              </Stack>
            )}
            <IconButton>
              {userGroupAccounts.length ? <PeopleOutline /> : <PersonOutline />}
            </IconButton>
          </Stack>
          <Menu
            elevation={3}
            id="menu-appbar"
            sx={(theme) => ({
              zIndex: theme.zIndex.drawer,
              "& .MuiPaper-root": {
                scrollbarWidth: "thin",
                scrollbarColor: `${theme.palette.text.disabled} ${theme.palette.background.default}`,
              },
            })}
            anchorEl={profileAnchor}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            open={Boolean(profileAnchor)}
            onClose={onCloseProfile}
          >
            <Paper
              component={ListSubheader}
              elevation={3}
              sx={{
                display: "flex",
                alignItems: "center",
                boxShadow: "none",
                borderRadius: 0,
                borderBottom: "1px solid",
                borderColor: "divider",
                "&:hover": {
                  bgcolor: "background.default",
                },
              }}
            >
              <Typography
                sx={{ cursor: "pointer" }}
                component="span"
                display="inline-block"
                onClick={() => copy()}
                role="button"
              >
                {self.email}
              </Typography>
              <Box flexGrow={1} pr={4} />
              <ThemeSwitch />
            </Paper>

            <MenuItem onClick={() => onProfileItemClick(navigateToSettingsPage)}>
              <ListItemIcon>
                <Settings />
              </ListItemIcon>
              <ListItemText>Settings</ListItemText>
            </MenuItem>

            <MenuItem onClick={() => onProfileItemClick(navigateToUsers)}>
              <ListItemIcon>
                <Group />
              </ListItemIcon>
              <ListItemText>Users</ListItemText>
            </MenuItem>

            <MenuItem onClick={() => onProfileItemClick(navigateToAlertsPage)}>
              <ListItemIcon>
                <Notifications />
              </ListItemIcon>
              <ListItemText>Alerts</ListItemText>
            </MenuItem>

            <MenuItem onClick={() => onProfileItemClick(navigateToChangelog)}>
              <ListItemIcon>
                <History />
              </ListItemIcon>
              <ListItemText>Changelog</ListItemText>
            </MenuItem>

            <Divider />

            {userGroupAccounts.length ? <ListSubheader>Switch User</ListSubheader> : null}

            {userGroupAccounts.map((member) => (
              <MenuItem
                key={member.email}
                dense
                title="Switch to this user view"
                disableRipple={member.isActive}
                sx={{ cursor: member.isActive ? "unset" : "pointer" }}
                onClick={() => switchAccount(member.email)}
              >
                <ListItemText>{member.description || member.email}</ListItemText>

                {member.isActive ? (
                  <CheckCircle
                    sx={{ ml: 3 }}
                    fontSize="small"
                    color={member.isLogged ? "secondary" : "action"}
                  />
                ) : null}
              </MenuItem>
            ))}

            {userGroupAccounts.length ? <Divider /> : null}

            <MenuItem onClick={() => onProfileItemClick(() => logout())}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              <ListItemText>Logout</ListItemText>
            </MenuItem>
          </Menu>
        </>
      )}
    </Stack>
  );
};
