import { Card, CardContent } from "@mui/material";
import { useAccount } from "@synota-io/synota-shared-ui";
import { MultiFactorAuthenticationForm } from "../forms/MultiFactorAuthenticationForm";

export const SecuritySettings = () => {
  const { mfa, isAdmin } = useAccount();

  if (!isAdmin || !mfa) {
    return null;
  }

  return (
    <Card>
      <CardContent>
        <MultiFactorAuthenticationForm />
      </CardContent>
    </Card>
  );
};
