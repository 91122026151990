import { EContractState, IContract } from "@synota-io/synota-shared-ui";

export type ContractAction = "rename" | "delete" | "renameCounterparty";

export const CONTRACT_STATE_ACTIONS_MAP: Record<EContractState, ContractAction[]> = {
  Completed: ["rename", "renameCounterparty"],
  Active: ["rename", "renameCounterparty"],
  Finishing: ["rename", "renameCounterparty"],
  Unsigned: ["rename", "renameCounterparty", "delete"],
  Upcoming: ["rename", "renameCounterparty"],
};

export const useContractActions = ({ contract }: { contract: IContract | null }) => {
  return CONTRACT_STATE_ACTIONS_MAP[contract?.contractState as EContractState] || [];
};
