import {
  Stack,
  Button,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import {
  ButtonRow,
  Form,
  FormSubmit,
  IContract,
  Modal,
  useBreakpoint,
  UseModalProps,
} from "@synota-io/synota-shared-ui";
import { ContractCustomField, useContractCustomFields } from "../hooks/useContractCustomFields";
import { chunk } from "lodash";

type Props = UseModalProps & {
  onAgree(): void;
  onDecline(): void;
  contract: IContract;
  isLoading?: boolean;
};

export const ContractInfoModal = ({
  onAgree,
  onDecline,
  contract,
  onClose,
  open,
  isLoading,
}: Props) => {
  const isLg = useBreakpoint("lg");
  const contractFields = useContractCustomFields(contract);

  return (
    <Modal
      open={open}
      onClose={onClose}
      fullWidth={!isLg}
      title={`Contract terms for "${contract.name}"`}
      actions={
        <Form
          sx={{ width: "100%" }}
          onSubmit={(e) => {
            e.preventDefault();
            onAgree();
          }}
        >
          <ButtonRow width="100%">
            <Button
              color="error"
              size="small"
              onClick={() => {
                onDecline();
              }}
            >
              Cancel
            </Button>
            <Box flexGrow={1} display="flex">
              <FormSubmit isLoading={isLoading} fullWidth variant="contained" color="secondary">
                Agree and Sign
              </FormSubmit>
            </Box>
          </ButtonRow>
        </Form>
      }
    >
      <TableContainer>
        <Stack direction="row" gap={10}>
          {isLg && contractFields.length > 12 ? (
            chunk(contractFields, Math.ceil(contractFields.length / 2)).map((partition, i) => {
              return (
                <Table sx={{ width: "50%" }} key={i}>
                  <TableBody>{partition.filter((field) => !!field.value).map(InfoRow)}</TableBody>
                </Table>
              );
            })
          ) : (
            <Table>
              <TableBody>{contractFields.filter((field) => !!field.value).map(InfoRow)}</TableBody>
            </Table>
          )}
        </Stack>
      </TableContainer>
    </Modal>
  );
};

const InfoRow = (field: ContractCustomField) => {
  return (
    <TableRow key={field.label}>
      <TableCell variant="head">{field.label}</TableCell>
      <TableCell>{field.value}</TableCell>
    </TableRow>
  );
};
