import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, AlertTitle, Typography } from "@mui/material";
import {
  Form,
  FormStep,
  FormSubmit,
  InputField,
  SwitchField,
  useAccount,
  useMultiFactorAuthentication,
  useToast,
  isEmail,
} from "@synota-io/synota-shared-ui";
import { useForm } from "react-hook-form";
import * as yup from "yup";

export const MultiFactorAuthenticationForm = () => {
  const toast = useToast();

  const { jwt, mfa, email, refetch } = useAccount();

  const {
    setMfa,
    isSetMfaLoading,
    confirmMfa,
    isConfirmMfaLoading,
    removeMfa,
    isRemoveMfaLoading,
  } = useMultiFactorAuthentication();

  const form = useForm({
    mode: "all",
    resolver: yupResolver(
      yup.object({
        mfaEmail: yup
          .string()
          .required("Email is required to receive the MFA code.")
          .test({ test: isEmail, message: "" }),
        mfaActive: yup.bool(),
      }),
    ),
    values: {
      mfaEmail: mfa?.email || email || "",
      mfaActive: mfa?.isActive || false,
    },
  });

  const onSubmit = form.handleSubmit(({ mfaEmail, mfaActive }) => {
    if (mfaActive) {
      return setMfa(
        { jwt, mfa_email: mfaEmail },
        {
          onSuccess: () => {
            confirmMfa(
              { jwt, mfa_email: mfaEmail },
              {
                onSuccess() {
                  refetch();
                  toast.success("MFA settings saved successfuly.");
                },
              },
            );
          },
        },
      );
    }

    removeMfa(
      { jwt },
      {
        onSuccess: () => {
          refetch();
          toast.success("MFA has been disabled successfuly.");
        },
      },
    );
  });

  const isLoading = isSetMfaLoading || isConfirmMfaLoading || isRemoveMfaLoading;

  return (
    <Form onSubmit={onSubmit}>
      <Typography variant="h6">Multi-Factor Authentication</Typography>
      <FormStep>
        {mfa ? (
          <Alert severity={mfa.isActive ? "success" : "error"}>
            <AlertTitle>
              {mfa.isActive ? (
                <span>
                  MFA Activated: <i>{mfa.email}</i>
                </span>
              ) : (
                <span>MFA Deactivated</span>
              )}
            </AlertTitle>
          </Alert>
        ) : null}
        <InputField
          type="email"
          autoComplete="off"
          fullWidth
          disabled={mfa?.isActive}
          readOnly={mfa?.isActive}
          header="Verification Email"
          name="mfaEmail"
          control={form.control}
        />
        <SwitchField
          color="success"
          label={"Enable MFA"}
          control={form.control}
          disabled={isLoading}
          hideFooter
          name="mfaActive"
        />
      </FormStep>
      <FormSubmit disabled={!form.formState.isDirty} isLoading={isLoading} />
    </Form>
  );
};
