import { Box, Tooltip } from "@mui/material";
import { AutoEllipsis, BadgeTypography, UserRole } from "@synota-io/synota-shared-ui";
import { UserGroupTableRowProps } from "./UserGroupTable";

const USER_ROLE_COLOR_MAP: Record<UserRole, "success" | "warning"> = {
  admin: "success",
  backup_admin: "success",
  pending_admin: "warning",
  pending_admin_needs_tos: "success",
  pending_backup_admin: "warning",
  pending_readonly: "warning",
  readonly: "success",
};

export const UserEmailRow = ({ user }: UserGroupTableRowProps) => {
  return (
    <Box mt={1} ml={1}>
      <Tooltip arrow placement="left" title={user.disabled ? "Disabled" : user.status}>
        <span>
          <BadgeTypography
            variant="subtitle4"
            status={user.disabled ? "error" : USER_ROLE_COLOR_MAP[user.role]}
          >
            <AutoEllipsis disableTooltip>{user.email}</AutoEllipsis>
          </BadgeTypography>
        </span>
      </Tooltip>
    </Box>
  );
};
