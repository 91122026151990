import { InputProps } from "@mui/material";
import {
  ButtonRow,
  FormButton,
  FormStep,
  FormSubmit,
  PasswordField,
  UseFormWizardStepProps,
} from "@synota-io/synota-shared-ui";
import { PropsWithChildren } from "react";

export interface PasswordProps extends UseFormWizardStepProps {
  isLoading?: boolean;
  inputLabel?: string;
  submitLabel?: string;
  InputProps?: Partial<InputProps>;
}

export const Password = ({
  children,
  control,
  submitLabel,
  inputLabel = "Password",
  isLoading,
  previous,
  InputProps = {},
}: PropsWithChildren<PasswordProps>) => {
  return (
    <FormStep>
      <PasswordField
        autoFocus
        InputProps={InputProps}
        autoComplete={InputProps.autoComplete}
        control={control}
        fullWidth
        name="password"
        header={inputLabel}
      />
      {children ? children : null}
      <ButtonRow>
        <FormSubmit
          isLoading={isLoading}
          fullWidth
          disabled={control.getFieldState("password").invalid}
          label={submitLabel}
        />
        {previous && (
          <FormButton variant="text" disabled={isLoading} onClick={() => previous()}>
            Back
          </FormButton>
        )}
      </ButtonRow>
    </FormStep>
  );
};
