import { Button } from "@mui/material";
import {
  ButtonRow,
  DetailsList,
  DetailsListItem,
  IContract,
  IContractCredit,
  Modal,
  useModal,
  UseModalOptions,
  UseModalProps,
} from "@synota-io/synota-shared-ui";
import { tableFields } from "./CreditsTable";

type CreditDetailModalState = { item: IContractCredit; contract: IContract };

export const useCreditDetailsModal = (options?: UseModalOptions<CreditDetailModalState>) => {
  return useModal<CreditDetailModalState>(options);
};

export const CreditDetailModal = ({
  state: { item, contract },
  ...props
}: UseModalProps<CreditDetailModalState>) => {
  return (
    <Modal
      fullWidth
      title="Credit"
      {...props}
      actions={
        <ButtonRow width="100%">
          <Button color="error" fullWidth onClick={() => props.onClose()}>
            Close
          </Button>
        </ButtonRow>
      }
    >
      <DetailsList disablePadding>
        {tableFields().map((field) => {
          const value = field.value({ item, contract });
          if (field.key in item && !item[field.key as keyof typeof item]) {
            return null;
          }
          return (
            <DetailsListItem xs key={field.key} title={field.title}>
              {value}
            </DetailsListItem>
          );
        })}
      </DetailsList>
    </Modal>
  );
};
