import {
  Box,
  Button,
  Card,
  CardContent,
  Slide,
  Stack,
  Table,
  TableBody,
  TableFooter,
  TableHead,
  Typography,
} from "@mui/material";
import { ReactNode } from "react";
import {
  ActionButton,
  AutoEllipsis,
  BodyCell,
  BodyRow,
  ButtonRow,
  Cell,
  HeadCell,
  HeadRow,
  IContract,
  IContractCredit,
  StatsBox,
  StickyTableContainer,
  formatDate,
  formatDollarAmount,
  useAccount,
  useBreakpoint,
  useContracts,
  useCredits,
  useModal,
} from "@synota-io/synota-shared-ui";
import { useParams } from "react-router-dom";
import { Add } from "@mui/icons-material";
import { CreateCreditFormModal } from "./CreateCreditFormModal";
import { CreditDetailModal, useCreditDetailsModal } from "./CreditDetailsModal";

interface CreditsTableField {
  title: string;
  key: string;
  value: (props: { item: IContractCredit; contract: IContract | null }) => ReactNode;
}

export const tableFields = (ellipsis?: boolean): Array<CreditsTableField> => [
  {
    value: ({ item }) => (
      <AutoEllipsis disabled={!ellipsis}>{`${item.shared_numeric_id || ""}`}</AutoEllipsis>
    ),
    key: "shared_numeric_id",
    title: "Supplier Credit ID",
  },
  {
    value: ({ item }) => (
      <AutoEllipsis disabled={!ellipsis}>{formatDate(item.effective_date) || ""}</AutoEllipsis>
    ),
    key: "effective_date",
    title: "Effective Date",
  },
  {
    value: ({ item }) => (
      <AutoEllipsis disabled={!ellipsis}>
        {formatDollarAmount(Number(item.amount) || 0)}
      </AutoEllipsis>
    ),
    key: "amount",
    title: "Credit Amount",
  },
  {
    value: ({ item }) => (
      <AutoEllipsis disabled={!ellipsis}>
        {formatDollarAmount(Number(item.amount_remaining) || 0)}
      </AutoEllipsis>
    ),
    key: "amount_remaining",
    title: "Remaining Amount",
  },
  {
    value: ({ item }) => <AutoEllipsis disabled={!ellipsis}>{item.title}</AutoEllipsis>,
    key: "title",
    title: "Title",
  },
  {
    value: ({ item }) => (
      <AutoEllipsis>{`${formatDate(item.service_start_time)} - ${formatDate(item.service_end_time)}`}</AutoEllipsis>
    ),
    key: "service_start_time",
    title: "Service day(s)",
  },
  {
    value: ({ item }) => (
      <AutoEllipsis disabled={!ellipsis}>{formatDate(item.created_at) || ""}</AutoEllipsis>
    ),
    key: "created_at",
    title: "Created At",
  },
  {
    value: ({ item }) => (
      <AutoEllipsis disabled={!ellipsis}>{item.credit_memo_number}</AutoEllipsis>
    ),
    key: "credit_memo_number",
    title: "Credit Memo #",
  },
  {
    value: ({ item }) => <AutoEllipsis disabled={!ellipsis}>{item.description}</AutoEllipsis>,
    key: "description",
    title: "Description",
  },
];

export const CreditsTable = () => {
  const isSm = useBreakpoint("sm");

  const { isSupplier, isAdmin } = useAccount();
  const { contractId } = useParams();
  const { contract } = useContracts(contractId);

  const { credits, isFetching, refetch } = useCredits(contractId);

  const addModal = useModal();
  const detailModal = useCreditDetailsModal();

  const balance =
    credits?.reduce(
      (prev, curr) => (curr.amount_remaining ? prev + Number(curr.amount_remaining) : prev),
      0,
    ) || 0;

  const fields = tableFields(true).slice(0, isSm ? 7 : 4);

  return (
    <Stack gap={6}>
      <ButtonRow flexWrap="wrap" alignItems="end">
        <StatsBox
          title="Contract Credit Balance"
          items={[formatDollarAmount(balance)]}
          disablePadding
          variant="primary"
        />
        <Box flexGrow={1} />
        {isSupplier && isAdmin ? (
          <>
            <ActionButton
              onClick={() => addModal.onOpen()}
              isLoading={isFetching}
              title="Add credit"
              color="primary"
              size="small"
            >
              <Add />
            </ActionButton>
          </>
        ) : null}
      </ButtonRow>
      <Slide in={Boolean(contract)} direction="up">
        <Card>
          <CardContent>
            <StickyTableContainer>
              <Table stickyHeader>
                <TableHead>
                  <HeadRow>
                    {fields.map((field) => (
                      <HeadCell key={field.key}>{field.title}</HeadCell>
                    ))}
                    <HeadCell>More</HeadCell>
                  </HeadRow>
                </TableHead>
                <TableBody>
                  {credits &&
                    credits.map((item) => {
                      return (
                        <BodyRow key={item.shared_uuid}>
                          {fields.map((field) => (
                            <Cell key={field.key}>
                              <Typography variant="subtitle4">
                                {field.value({ item, contract })}
                              </Typography>
                            </Cell>
                          ))}
                          <Cell>
                            <Button
                              size="small"
                              sx={{ padding: 0 }}
                              color="primary"
                              variant="contained"
                              onClick={() => contract && detailModal.onOpen({ contract, item })}
                            >
                              Details
                            </Button>
                          </Cell>
                        </BodyRow>
                      );
                    })}
                </TableBody>
                {!isFetching && !credits?.length ? (
                  <TableFooter>
                    <BodyRow>
                      <BodyCell>No credits found.</BodyCell>
                    </BodyRow>
                  </TableFooter>
                ) : null}
              </Table>
            </StickyTableContainer>
          </CardContent>
        </Card>
      </Slide>
      {contract && addModal.open && (
        <CreateCreditFormModal {...addModal} onSuccess={() => refetch()} contract={contract} />
      )}
      {detailModal.open && <CreditDetailModal {...detailModal} />}
    </Stack>
  );
};
