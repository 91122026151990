import { TableContainer, Table, TableHead, TableBody } from "@mui/material";
import {
  BodyCell,
  BodyRow,
  CollapsedCard,
  formatDate,
  formatDollarAmount,
  formatEnergyAmount,
  getMWhFromKWh,
  HeadCell,
  HeadRow,
  IContract,
} from "@synota-io/synota-shared-ui";

interface Props {
  contract: IContract | null;
}

export const ContractBlocks = ({ contract }: Props) => {
  if (!contract || !contract.blocks.length) {
    return null;
  }

  return (
    <CollapsedCard title="Blocks">
      <TableContainer sx={{ px: 4, pb: 2 }}>
        <Table>
          <TableHead>
            <HeadRow>
              <HeadCell>Block Name</HeadCell>
              <HeadCell>Start Date</HeadCell>
              <HeadCell>End Date</HeadCell>
              <HeadCell>Block Size (MW)</HeadCell>
              <HeadCell>Block Price ($/MWh)</HeadCell>
              <HeadCell>Schedule</HeadCell>
            </HeadRow>
          </TableHead>
          <TableBody>
            {contract.blocks.map((block) => (
              <BodyRow key={block.id}>
                <BodyCell>{block.name}</BodyCell>
                <BodyCell>{formatDate(block.start_date)}</BodyCell>
                <BodyCell>{formatDate(block.end_date)}</BodyCell>
                <BodyCell>{formatEnergyAmount(getMWhFromKWh(Number(block.size)))}</BodyCell>
                <BodyCell>{formatDollarAmount(Number(block.price) * 1000)}</BodyCell>
                <BodyCell>{block.block_type_description}</BodyCell>
              </BodyRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </CollapsedCard>
  );
};
