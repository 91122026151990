import { styled, ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  SynotaDarkTheme,
  SynotaLightTheme,
  ThemesProvider,
  ToastProvider,
  dayjs,
} from "@synota-io/synota-shared-ui";
import { PropsWithChildren } from "react";
import { FEATURE_ENABLE_DONATION_SHOWCASE_MODE } from "./utils/environment";
import { useSearchParams } from "react-router-dom";

const RootDiv = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  color: theme.palette.text.primary,
  width: "100%",
  height: "100%",
  display: "flex",
  overflow: "hidden",
}));

export function AppProvider({ children }: PropsWithChildren) {
  const [searchParams] = useSearchParams();

  const rootDiv = (
    <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
      <ToastProvider>{children}</ToastProvider>
    </LocalizationProvider>
  );

  if (FEATURE_ENABLE_DONATION_SHOWCASE_MODE) {
    return (
      <ThemeProvider theme={searchParams.has("dark") ? SynotaDarkTheme : SynotaLightTheme}>
        {rootDiv}
      </ThemeProvider>
    );
  }

  return (
    <ThemesProvider>
      <RootDiv>{rootDiv}</RootDiv>
    </ThemesProvider>
  );
}
