import { Box } from "@mui/material";
import {
  ButtonRow,
  Form,
  FormButton,
  FormStep,
  FormSubmit,
  InputField,
  Modal,
  UseModalProps,
  InviteUserRole,
  useAccount,
  isEmail,
  ToggleField,
  useToast,
  setControlFieldErrors,
  useUsersMutations,
} from "@synota-io/synota-shared-ui";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { USER_ROLE_USER_FRIENDLY_NAME } from "../../explorer/tables/userGroup/UserGroupTable";

export interface InviteUserFormValues {
  user_email: string;
  role: {
    value: InviteUserRole;
    label: string;
  };
}

const ALLOWED_ROLES = [InviteUserRole.pending_backup_admin, InviteUserRole.pending_readonly];

export const createInvoiceSchema = yup.object({
  user_email: yup
    .string()
    .required("Email is required!")
    .test({ test: isEmail, message: "Invalid email" }),
  role: yup.object({
    value: yup.string().required("Role is required!").oneOf(ALLOWED_ROLES),
    label: yup.string().required("Role is required!"),
  }),
});

export const InviteUserFormModal = ({
  open,
  onClose,
  onSuccess,
}: UseModalProps & { onSuccess: () => void }) => {
  const toast = useToast();

  const { jwt } = useAccount();
  const { isPending, inviteUser } = useUsersMutations();

  const { control, handleSubmit, formState } = useForm<InviteUserFormValues>({
    resolver: yupResolver(createInvoiceSchema) as any,
    mode: "onBlur",
    defaultValues: {
      user_email: "",
      role: {
        value: InviteUserRole.pending_readonly,
        label: USER_ROLE_USER_FRIENDLY_NAME[InviteUserRole.pending_readonly],
      },
    },
  });

  const onSubmit = handleSubmit(({ user_email, role }) => {
    inviteUser(
      {
        jwt,
        role: role.value,
        user_email,
      },
      {
        onSuccess({ data }) {
          if (data.success) {
            onSuccess();
            toast.success("Successfully invited " + user_email + "!");
            return onClose();
          }

          setControlFieldErrors(control, data.field_errors);
        },
      },
    );
  });

  return (
    <Modal
      maxWidth="xs"
      fullWidth
      open={open}
      onClose={onClose}
      title="Invite new user"
      actions={
        <ButtonRow width="100%">
          <FormButton
            variant="text"
            color="error"
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </FormButton>
          <Box flexGrow={1} display="flex">
            <FormSubmit
              disabled={!formState.isValid}
              isLoading={isPending}
              form="invite-user-form"
              fullWidth
            >
              Send Invite
            </FormSubmit>
          </Box>
        </ButtonRow>
      }
    >
      <Form id="invite-user-form" onSubmit={onSubmit}>
        <FormStep>
          <ToggleField
            options={ALLOWED_ROLES.map((role) => ({
              label: USER_ROLE_USER_FRIENDLY_NAME[role],
              value: role,
              color: "primary",
            }))}
            control={control}
            size="small"
            header="Role"
            placeholder="Select role here"
            name="role"
            fullWidth
          />
          <InputField
            size="small"
            autoComplete="off"
            header="Email"
            type="email"
            required
            fullWidth
            control={control}
            name="user_email"
          />
        </FormStep>
      </Form>
    </Modal>
  );
};
