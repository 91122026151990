import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useFieldArray, useForm } from "react-hook-form";

import * as yup from "yup";
import { Close, Delete, Done } from "@mui/icons-material";
import { Network, validate as validateBitcoinAddress } from "bitcoin-address-validation";
import { BITCOIN_NETWORK } from "../../../utils/environment";
import {
  Form,
  FormButton,
  FormInlineStep,
  FormStep,
  FormSubmit,
  InputField,
  NumericField,
  useAccount,
  useAutomaticPayments,
  useToast,
} from "@synota-io/synota-shared-ui";

const PERCENT_MSG = "Please type a percentage";
const POSITIVE_MSG = "Must be more than 0";
const MAX_MSG = "Must not be greater than 100";
const DISTRIBUTION_MSG = "Distribution must be 100% in total";

const addressesSchema = yup.object({
  addresses: yup
    .array(
      yup.object({
        label: yup.string(),
        value: yup
          .string()
          .required("Address is required")
          .test("bitcoin-address", "Invalid bitcoin address!", (value) =>
            validateBitcoinAddress(value, BITCOIN_NETWORK as Network),
          ),
        percentage: yup
          .number()
          .moreThan(0, POSITIVE_MSG)
          .max(100, MAX_MSG)
          .typeError(PERCENT_MSG)
          .required(PERCENT_MSG),
      }),
    )
    .required(),
});

export type AddressesFormValues = {
  addresses: Array<{
    label?: string;
    value: string;
    percentage: number;
  }>;
};

const EMPTY_ADDRESSES = [
  {
    label: "",
    value: "",
    percentage: 0,
  },
];

export const AutomaticPaymentAddressesForm = () => {
  const { withdrawalAddresses, setWithdrawalAddresses, isPending } = useAutomaticPayments();
  const { jwt, isAdmin } = useAccount();
  const { success } = useToast();

  const addressesForm = useForm<AddressesFormValues>({
    mode: "all",
    resolver: yupResolver(addressesSchema, { abortEarly: false }),
    defaultValues: {
      addresses: EMPTY_ADDRESSES,
    },
    values: withdrawalAddresses
      ? {
          addresses: withdrawalAddresses.current_addresses.map((a) => ({
            label: a.description,
            value: a.address,
            percentage: Number(a.percentage),
          })),
        }
      : { addresses: EMPTY_ADDRESSES },
  });

  const addressFieldArray = useFieldArray({
    control: addressesForm.control,
    name: "addresses",
  });

  const onAddressesSubmit = ({ addresses }: AddressesFormValues) => {
    const input = {
      jwt,
      address_distribution: addresses.map((address) => ({
        address: address.value,
        description: address.label,
        percentage: address.percentage,
      })),
    };

    setWithdrawalAddresses(input, {
      onSuccess() {
        success("Automatic withdrawal addresses saved successfully.");
      },
    });
  };

  const addressesArray = addressesForm.watch("addresses");

  const distributionSum = addressesArray.reduce(
    (prev, curr) => prev + (Number(curr.percentage) || 0),
    0,
  );

  const isDistributed = distributionSum === 100;

  return (
    <Form onSubmit={addressesForm.handleSubmit(onAddressesSubmit)}>
      <Typography variant="h6">Automatic Withdrawal Addresses</Typography>
      <FormStep spacing={2}>
        {addressesArray.map((_item, idx) => {
          return (
            <FormInlineStep key={idx}>
              <InputField
                size="small"
                fullWidth
                readOnly={!isAdmin}
                header={idx === 0 ? "Address Label" : undefined}
                control={addressesForm.control}
                name={`addresses.${idx}.label`}
              />
              <InputField
                required
                size="small"
                readOnly={!isAdmin}
                autoComplete="off"
                fullWidth
                header={idx === 0 ? "Bitcoin Address" : undefined}
                control={addressesForm.control}
                name={`addresses.${idx}.value`}
              />
              <Box width="13em" minWidth="13em">
                <NumericField
                  required
                  readOnly={!isAdmin}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                  }}
                  end={
                    <Tooltip title="Remove">
                      <span>
                        <IconButton
                          disabled={!isAdmin}
                          onClick={() => isAdmin && addressFieldArray.remove(idx)}
                          color="error"
                          size="small"
                        >
                          <Delete fontSize="small" />
                        </IconButton>
                      </span>
                    </Tooltip>
                  }
                  size="small"
                  header={idx === 0 ? "Percentage" : undefined}
                  control={addressesForm.control}
                  name={`addresses.${idx}.percentage`}
                />
              </Box>
            </FormInlineStep>
          );
        })}

        <Stack direction="row" alignItems="start">
          {isDistributed ? null : (
            <Typography pt={3} color="error" variant="body1">
              {DISTRIBUTION_MSG}
            </Typography>
          )}
          <Box flexGrow={1} pt={2} textAlign="right" pr={5}>
            <Typography color={isDistributed ? "inherit" : "error"}>Total</Typography>
          </Box>
          <Stack direction="row" alignItems="start" width="13em" spacing={2}>
            <TextField
              size="small"
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                readOnly: true,
              }}
              error={!isDistributed}
              sx={(theme) => ({
                mr: "0.56em",
                "& fieldset": {
                  borderColor: isDistributed ? theme.palette.success.main : undefined,
                },
              })}
              value={distributionSum}
            />
            <IconButton color="success" size="small" disabled>
              {isDistributed ? (
                <Done color="success" fontSize="small" />
              ) : (
                <Close color="error" fontSize="small" />
              )}
            </IconButton>
          </Stack>
        </Stack>
      </FormStep>

      <Stack direction="row" width="100%">
        <FormButton
          disabled={!isAdmin}
          onClick={() => addressFieldArray.append({ label: "", value: "", percentage: 0 })}
        >
          Add New Line
        </FormButton>
        <Box flexGrow={1} />
        <FormSubmit isLoading={isPending} disabled={!isDistributed || !isAdmin} />
      </Stack>
    </Form>
  );
};
