import { ButtonLink, ButtonLinkProps, ButtonRow, IContract } from "@synota-io/synota-shared-ui";
import { generatePath, useMatch } from "react-router-dom";
import { CONTRACT_CREDITS_PATH, CONTRACT_EXPOSURE_PATH, CONTRACT_PATH } from "../../../paths";
import { ContractMoreMenu } from "./ContractMoreMenu";
import { useContractActions } from "../hooks/useContractActions";
import { FEATURE_ENABLE_AR_EXPOSURE, FEATURE_ENABLE_CREDITS } from "../../../utils/environment";

interface Props {
  contract: IContract | null;
}

const buttons = [
  {
    title: "Exposure",
    path: CONTRACT_EXPOSURE_PATH,
    enabled: FEATURE_ENABLE_AR_EXPOSURE,
  },
  {
    title: "Credits",
    path: CONTRACT_CREDITS_PATH,
    enabled: FEATURE_ENABLE_CREDITS,
  },
  {
    title: "Dashboard",
    path: CONTRACT_PATH,
    enabled: true,
  },
];

export const ContractMenu = ({ contract }: Props) => {
  const contractActions = useContractActions({ contract });

  if (!contract) {
    return null;
  }

  return (
    <ButtonRow role="menu" gap={1}>
      {buttons
        .filter((b) => b.enabled)
        .map((button) => (
          <ContractMenuButton
            key={button.title}
            path={button.path}
            to={generatePath(button.path, { contractId: contract?.uuid || null })}
          >
            {button.title}
          </ContractMenuButton>
        ))}
      <ContractMoreMenu actions={contractActions} size="small" contract={contract} />
    </ButtonRow>
  );
};

const ContractMenuButton = ({ children, path, ...props }: ButtonLinkProps & { path: string }) => {
  const isActive = Boolean(useMatch({ path, end: true }));
  return (
    <ButtonLink
      role="menuitem"
      size="small"
      color={isActive ? "primary" : "neutral"}
      aria-selected={isActive}
      {...props}
    >
      {children}
    </ButtonLink>
  );
};
