import { Stack, Typography } from "@mui/material";
import { SettlementsTable } from "../tables/SettlementsTable";

export const SettlementsPage = () => {
  return (
    <Stack gap={6}>
      <Typography variant="h5">Settlements</Typography>
      <SettlementsTable
        canFilter
        fields={[
          "contractName",
          "settlementStart",
          "energyDeliveredWithUnit",
          "hostFeeInvoice",
          "customerPayment",
          "effectiveRateMwh",
        ]}
      />
    </Stack>
  );
};
