import {
  useTheme,
  useMediaQuery,
  Box,
  Paper,
  Tooltip,
  Chip,
  Stack,
  StackProps,
  Typography,
} from "@mui/material";
import {
  CounterLabel,
  CounterLabelProps,
  MinerManagementInfoResponse,
  UseMinerManagementReturn,
} from "@synota-io/synota-shared-ui";
import { useState } from "react";

interface Props {
  management: UseMinerManagementReturn;
}

const MINER_STATUS_COLOR_MAP: Record<
  keyof MinerManagementInfoResponse["miners"],
  CounterLabelProps["status"]
> = {
  deployed: "info",
  disabled: "error",
  hashing: "success",
  inRepair: "info",
  offline: "error",
  online: "success",
  pending: "warning",
  retired: "error",
  sleeping: "warning",
  total: "info",
};

export const MinersStatus = ({ management, ...props }: Props & StackProps<typeof Typography>) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const theme = useTheme();
  const isLg = useMediaQuery(theme.breakpoints.only("lg"));
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));

  if (!management.minerManagementInfo) {
    return null;
  }

  const {
    miners: { online, offline, total, ...miners },
  } = management.minerManagementInfo;

  const otherStatus = Object.entries({ ...miners, total });

  const tooltip = otherStatus.length ? (
    <Stack
      component={Paper}
      elevation={2}
      columnGap={12}
      rowGap={4}
      px={4}
      py={6}
      direction="row"
      flexWrap="wrap"
    >
      {otherStatus
        .sort(([, a], [, b]) => (a < b ? 1 : -1))
        .map(([k, value]) => (
          <Stack key={k} direction="row" width="40%">
            <Typography key={k} noWrap variant="body2" textTransform="capitalize">
              {k}
            </Typography>
            <Box flexGrow={1} />
            <CounterLabel
              variant="subtitle3"
              alignSelf="end"
              justifySelf="end"
              status={MINER_STATUS_COLOR_MAP[k as keyof MinerManagementInfoResponse["miners"]]}
            >
              {value}
            </CounterLabel>
          </Stack>
        ))}
    </Stack>
  ) : null;

  return (
    <Stack
      ref={(node: HTMLDivElement) => {
        setAnchorEl(node);
      }}
      gap={props.direction === "row" ? 3 : 4}
      alignItems="center"
      component={Typography}
      variant="h5"
      height="100%"
      {...props}
    >
      <Tooltip
        enterTouchDelay={0}
        placement={isXs || isLg ? "top" : "left"}
        PopperProps={{ anchorEl }}
        slotProps={{
          tooltip: { sx: { p: 0, borderRadius: "50%", maxWidth: "32em" } },
        }}
        title={tooltip}
      >
        <Chip
          role="button"
          sx={{ cursor: "pointer" }}
          onClick={() => management.refetch()}
          label={`${total || 0} Miners`}
        />
      </Tooltip>
      <Chip color="success" label={`${online || 0} Online`} />
      <Chip color="error" label={`${offline || 0} Offline`} />
    </Stack>
  );
};
