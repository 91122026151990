import { Stack, Box, Typography } from "@mui/material";
import {
  formatDollarAmount,
  formateDateTime,
  IContract,
  IStatementData,
  useBitcoinRate,
} from "@synota-io/synota-shared-ui";

export const MetricsFooter = ({
  contract,
  mode,
  statement,
}: {
  contract?: IContract | null;
  statement: IStatementData | null;
  mode: "A/R" | "A/P";
}) => {
  const { getBitcoin } = useBitcoinRate();
  if (!statement) {
    return null;
  }
  return (
    <Stack pt={1} direction="row" spacing={2}>
      <span>{mode}:</span>
      <span>{formatDollarAmount(statement.currentAmountOutstanding || 0)}</span>
      {mode === "A/P" ? (
        <Typography variant="inherit" color="text.secondary">
          ({getBitcoin(statement.currentAmountOutstanding || 0)} BTC)
        </Typography>
      ) : null}
      <Box flexGrow={1} />
      {contract && statement.lastInvoiceTime ? (
        <Typography variant="inherit" color="text.secondary" display="flex" gap={2}>
          <span>Last Invoice:</span>
          <span>{formateDateTime(statement.lastInvoiceTime)} UTC</span>
        </Typography>
      ) : null}
    </Stack>
  );
};
