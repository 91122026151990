import { Announcement, IContract, useAccount } from "@synota-io/synota-shared-ui";
import { ForemanSetupForm } from "./ForemanSetupForm";
import { OtherSetupForm } from "./OtherSetupForm";
import { LeakAdd, Lock, Poll } from "@mui/icons-material";
import { Stack, List, ListItem, ListItemIcon, Typography, ListItemText } from "@mui/material";

interface Props {
  contract: IContract;
  vendor: string | null;
}

const PROVIDER_FORM_COMPONENT_MAP: Record<string, (props: Props) => JSX.Element | null> = {
  foreman: ForemanSetupForm,
  other: OtherSetupForm,
};

export const ManagementSetupForm = ({ contract, vendor }: Props) => {
  const { isAdmin } = useAccount();

  const ProviderForm = vendor ? PROVIDER_FORM_COMPONENT_MAP[vendor] : null;

  if (isAdmin) {
    if (ProviderForm) {
      return <ProviderForm contract={contract} vendor={vendor} />;
    }

    return (
      <Stack direction={{ md: "row", lg: "column", xl: "row" }} rowGap={4} alignItems="center">
        <Typography variant="subtitle1">
          Link this contract to your miner management software
        </Typography>

        <List dense disablePadding>
          <ListItem>
            <ListItemIcon>
              <Lock fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              <Typography variant="subtitle2">Private and secure API connection</Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <Poll fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              <Typography variant="subtitle2">View your real-time operations data</Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <LeakAdd fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              <Typography variant="subtitle2">Share real-time data with counterparty</Typography>
            </ListItemText>
          </ListItem>
        </List>
      </Stack>
    );
  }
  return <Announcement>Only admin accounts can link miner management accounts.</Announcement>;
};
