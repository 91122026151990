import { Bolt } from "@mui/icons-material";
import {
  Button,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
  Box,
  Stack,
  StackProps,
  ListItem,
} from "@mui/material";
import {
  DATE_TIME_FORMAT_DEFAULT,
  DetailsList,
  formatAmount,
  getkWhFromWattHours,
  getMWhFromWattHours,
  RefreshIcon,
  UseMinerManagementReturn,
} from "@synota-io/synota-shared-ui";

interface Props {
  management: UseMinerManagementReturn;
}

export const MinersInfo = ({
  management: { updatedAt, minerManagementInfo, refetch, isFetching },
  ...props
}: Props & StackProps) => {
  if (!minerManagementInfo) {
    return null;
  }

  const watts = Number(minerManagementInfo.power_draw) || 0;
  const powerDraw =
    watts > 1_000_500
      ? `${formatAmount(getMWhFromWattHours(watts), 3)} MW`
      : `${formatAmount(getkWhFromWattHours(watts), 3)} kW`;

  return (
    <Stack height="100%" justifyContent="start" alignItems="start" {...props}>
      <DetailsList disablePadding>
        <ListItem disablePadding>
          <ListItemIcon>
            <Bolt fontSize="large" />
          </ListItemIcon>
          <ListItemText>
            <Typography fontWeight={800}>Estimated Power Draw</Typography>
            <Typography>{powerDraw}</Typography>
          </ListItemText>
        </ListItem>
      </DetailsList>
      <Box flexGrow={1} />
      {updatedAt ? (
        <Tooltip title={updatedAt.format(DATE_TIME_FORMAT_DEFAULT)}>
          <Button
            color="neutral"
            startIcon={<RefreshIcon isLoading={isFetching} />}
            onClick={() => refetch()}
            size="small"
          >
            <Typography variant="inherit" color="text.secondary" fontStyle="italic">
              Updated {updatedAt.fromNow()}.
            </Typography>
          </Button>
        </Tooltip>
      ) : null}
    </Stack>
  );
};
