import React from "react";
import ReactDOM from "react-dom/client";
import { AppProvider } from "./AppProvider";
import { BrowserRouter } from "react-router-dom";
import { initSentry } from "./setupSentry";
import { FEATURE_ENABLE_DONATION_SHOWCASE_MODE } from "./utils/environment";

import "./index.css";
if (FEATURE_ENABLE_DONATION_SHOWCASE_MODE) {
  import("./embedded.css");
}

import App from "./App";

initSentry();

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AppProvider>
        <App />
      </AppProvider>
    </BrowserRouter>
  </React.StrictMode>,
);
