import {
  Card,
  CardContent,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  IContract,
  formatDollarAmount,
  formatEnergyAmount,
  getBitcoinFromSats,
  getFormattedEnergyEffectiveRate,
  useAccount,
  useRevenue,
  useStatementDataMetrics,
} from "@synota-io/synota-shared-ui";
import { MetricsFooter } from "./MetricsFooter";

interface Props {
  contract?: IContract | null;
}

export const ConsumerMetricsTable = ({ contract }: Props) => {
  const { hasLightningNode } = useAccount();
  const { ytd, priorMonth, mtd, hasData, isLoading } = useStatementDataMetrics({ contract });

  const { revenueByYTD, revenueByMTD, revenueByPriorMonth } = useRevenue();

  return (
    <Slide in direction="up">
      <Card>
        <CardContent sx={{ px: { lg: 2, xl: 6 }, overflow: { lg: "visible" } }}>
          {isLoading && !hasData ? null : (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell />

                    <TableCell variant="head">Energy Consumed (MWh)</TableCell>

                    {hasLightningNode ? <TableCell variant="head">Deposits (BTC)</TableCell> : null}

                    <TableCell variant="head">Total Amount Invoiced (USD)</TableCell>

                    <TableCell variant="head">Effective Rate ($/MWh)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ytd.statement ? (
                    <TableRow>
                      <TableCell variant="head">YTD</TableCell>

                      <TableCell>{formatEnergyAmount(ytd.statement.unitsDeliveredMwh)}</TableCell>

                      {hasLightningNode ? (
                        <TableCell>{getBitcoinFromSats(revenueByYTD)}</TableCell>
                      ) : null}

                      <TableCell>{formatDollarAmount(ytd.statement.amountDue)}</TableCell>

                      <TableCell>
                        {getFormattedEnergyEffectiveRate(
                          ytd.statement.amountDue,
                          ytd.statement.unitsDeliveredMwh,
                        )}
                      </TableCell>
                    </TableRow>
                  ) : null}
                  {priorMonth.statement ? (
                    <TableRow>
                      <TableCell variant="head">Prior Month</TableCell>

                      <TableCell>
                        {formatEnergyAmount(priorMonth.statement.unitsDeliveredMwh)}
                      </TableCell>

                      {hasLightningNode ? (
                        <TableCell>{getBitcoinFromSats(revenueByPriorMonth)}</TableCell>
                      ) : null}

                      <TableCell>{formatDollarAmount(priorMonth.statement.amountDue)}</TableCell>

                      <TableCell>
                        {getFormattedEnergyEffectiveRate(
                          priorMonth.statement.amountDue,
                          priorMonth.statement.unitsDeliveredMwh,
                        )}
                      </TableCell>
                    </TableRow>
                  ) : null}
                  {mtd.statement ? (
                    <TableRow>
                      <TableCell variant="head">MTD</TableCell>

                      <TableCell>{formatEnergyAmount(mtd.statement.unitsDeliveredMwh)}</TableCell>

                      {hasLightningNode ? (
                        <TableCell>{getBitcoinFromSats(revenueByMTD)}</TableCell>
                      ) : null}

                      <TableCell>{formatDollarAmount(mtd.statement.amountDue)}</TableCell>

                      <TableCell>
                        {getFormattedEnergyEffectiveRate(
                          mtd.statement.amountDue,
                          mtd.statement.unitsDeliveredMwh,
                        )}
                      </TableCell>
                    </TableRow>
                  ) : null}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TableCell />
                    <TableCell colSpan={3}>
                      <MetricsFooter contract={contract} mode="A/P" statement={mtd.statement} />
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          )}
        </CardContent>
      </Card>
    </Slide>
  );
};
