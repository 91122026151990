import {
  Box,
  Card,
  CardContent,
  Slide,
  Stack,
  Table,
  TableBody,
  TableFooter,
  TableHead,
  Typography,
  TypographyProps,
} from "@mui/material";
import { ReactNode } from "react";
import {
  ActionButton,
  BodyCell,
  BodyRow,
  ButtonRow,
  Cell,
  HeadCell,
  HeadRow,
  IUserGroupUser,
  StickyTableContainer,
  UserRole,
  useAccount,
  useBreakpoint,
  useModal,
  useUsers,
  useWindowSize,
} from "@synota-io/synota-shared-ui";
import { GroupAdd } from "@mui/icons-material";
import { InviteUserFormModal } from "../../../settings/modals/InviteUserFormModal";
import { DASHBOARD_URL } from "../../../../utils/environment";
import { UserActionsRow } from "./UserActionsRow";
import { UserEmailRow } from "./UserEmailRow";
import { UserRoleRow } from "./UserRoleRow";

export type UserGroupTableRowProps = {
  user: IUserGroupUser;
  field: UserGroupTableField;
  onRefetch: () => void;
};

interface UserGroupTableField {
  key: string;
  color?: TypographyProps["color"];
  value: (props: UserGroupTableRowProps) => ReactNode;
}

export const USER_ROLE_USER_FRIENDLY_NAME: Record<UserRole, "Admin" | "Read-only"> = {
  admin: "Admin",
  backup_admin: "Admin",
  pending_admin: "Admin",
  pending_admin_needs_tos: "Admin",
  pending_backup_admin: "Admin",
  pending_readonly: "Read-only",
  readonly: "Read-only",
};

export const UserGroupTable = () => {
  const { isAdmin } = useAccount();
  const { users, isFetching, refetch } = useUsers();

  const isMd = useBreakpoint("md");
  const inviteModal = useModal();

  // Re-renders ellipsis calculation on window resize
  useWindowSize();

  const tableFields: UserGroupTableField[] = (
    [
      {
        value: UserEmailRow,
        key: "Email",
      },
      {
        value: UserRoleRow,
        key: "Role",
      },
      isMd && {
        value: ({ user, field }) => (
          <Typography variant="subtitle4" color={field.color}>
            {user.description || ""}
          </Typography>
        ),
        key: "Description",
      },
      isAdmin && {
        value: UserActionsRow,
        key: "Actions",
      },
    ] as UserGroupTableField[]
  ).filter(Boolean);

  return (
    <Stack gap={6}>
      <ButtonRow>
        <Typography variant="h5">Users</Typography>
        <Box flexGrow={1} />
        {DASHBOARD_URL && isAdmin && (
          <ActionButton
            color="primary"
            title="Invite New User"
            size="small"
            onClick={() => inviteModal.onOpen()}
            isLoading={isFetching}
          >
            <GroupAdd fontSize="small" />
          </ActionButton>
        )}
      </ButtonRow>
      <Slide in direction="up">
        <Card>
          <CardContent>
            <StickyTableContainer>
              <Table stickyHeader>
                <TableHead>
                  <HeadRow>
                    {tableFields.map((field) => (
                      <HeadCell key={field.key}>{field.key}</HeadCell>
                    ))}
                  </HeadRow>
                </TableHead>
                <TableBody>
                  {users &&
                    users.map((user) => {
                      return (
                        <BodyRow key={user.email}>
                          {tableFields.map((field) => (
                            <Cell key={field.key}>
                              <field.value field={field} user={user} onRefetch={refetch} />
                            </Cell>
                          ))}
                        </BodyRow>
                      );
                    })}
                </TableBody>
                {!isFetching && !users.length ? (
                  <TableFooter>
                    <BodyRow>
                      <BodyCell>No users found.</BodyCell>
                    </BodyRow>
                  </TableFooter>
                ) : null}
              </Table>
            </StickyTableContainer>
          </CardContent>
        </Card>
      </Slide>
      {inviteModal.open ? <InviteUserFormModal {...inviteModal} onSuccess={refetch} /> : null}
    </Stack>
  );
};
