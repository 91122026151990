import { Stack, Typography } from "@mui/material";
import { AlertSettingsForm } from "../forms/AlertSettingsForm";

export const AlertsPage = () => {
  return (
    <Stack gap={6} maxWidth="100%">
      <Typography variant="h5">Alerts</Typography>
      <AlertSettingsForm />
    </Stack>
  );
};
