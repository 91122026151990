import { Stack, Typography } from "@mui/material";
import { useAccount } from "@synota-io/synota-shared-ui";
import { ConsumerSettings } from "../components/ConsumerSettings";
import { SupplierSettings } from "../components/SupplierSettings";
import { SecuritySettings } from "../components/SecuritySettings";

export const SettingsPage = () => {
  const { isConsumer, isSupplier } = useAccount();

  return (
    <Stack spacing={6}>
      <Stack gap={6}>
        <Typography variant="h5">Settings</Typography>
        {isConsumer && <ConsumerSettings />}
        {isSupplier && <SupplierSettings />}
        <SecuritySettings />
      </Stack>
    </Stack>
  );
};
