import { useEffect } from "react";
import { useAccount, useAutomaticPayments, useModal } from "@synota-io/synota-shared-ui";
import { useSearchParams } from "react-router-dom";

export const useRequiredAddressModal = () => {
  const { isAdmin, hasLightningNode, isConsumer, isReady } = useAccount();
  const { requiresAddress } = useAutomaticPayments();
  const [search] = useSearchParams();
  const modal = useModal();

  useEffect(() => {
    if (
      isReady &&
      isConsumer &&
      hasLightningNode &&
      isAdmin &&
      requiresAddress &&
      !search.has("disable_modal")
    ) {
      modal.onOpen();
    } else {
      modal.onClose();
    }
  }, [hasLightningNode, isAdmin, isConsumer, isReady, modal, requiresAddress, search]);

  return modal;
};
