import { Stack } from "@mui/material";
import {
  Announcement,
  FlexLoadingBlock,
  UseMinerManagementReturn,
} from "@synota-io/synota-shared-ui";

export const MinerManagementError = ({ management }: { management: UseMinerManagementReturn }) => {
  if (management.error) {
    if (management.isFetching) {
      return <FlexLoadingBlock />;
    }
    return (
      <Stack alignItems="center" justifyContent="center" flexGrow={1} height="100%" width="65%">
        <Announcement sx={{ cursor: "pointer" }} role="button" onClick={() => management.refetch()}>
          Failed to load Miner Management, click here to try again
        </Announcement>
      </Stack>
    );
  }

  return null;
};
