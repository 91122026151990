import { Outlet } from "react-router-dom";
import { RequiredAddressModal } from "../contracts/components/RequiredAddressModal";
import { useRequiredAddressModal } from "../contracts/hooks/useRequiredAddressModal";
import { LoadingPage } from "../../shared/pages/LoadingPage";

export const DashboardOutlet = () => {
  const requiredAddressModal = useRequiredAddressModal();
  return (
    <LoadingPage>
      <Outlet />
      <RequiredAddressModal {...requiredAddressModal} />
    </LoadingPage>
  );
};
