import { Stack, Typography } from "@mui/material";
import { FeesAndRevenueTable } from "../tables/FeesAndRevenueTable";
import { useNavigate } from "react-router-dom";
import { useEffect, useMemo } from "react";
import {
  ButtonRow,
  DateField,
  Dayjs,
  dayjs,
  isDayjs,
  useAccount,
  useRevenue,
} from "@synota-io/synota-shared-ui";
import { useForm, useWatch } from "react-hook-form";

interface Filters {
  startDate: Dayjs | null;
  endDate: Dayjs | null;
}

export const MinerDepositHistoryPage = () => {
  const navigate = useNavigate();
  const defaultDate = useMemo(
    () => ({
      startDate: dayjs().startOf("month"),
      endDate: dayjs().startOf("day"),
    }),
    [],
  );

  const { isConsumer } = useAccount();
  const { control } = useForm<Filters>({
    defaultValues: {
      startDate: defaultDate.startDate,
      endDate: defaultDate.endDate,
    },
  });

  const values = useWatch<Filters>({ control });
  const { revenueItems, isLoading } = useRevenue({
    start_time: isDayjs(values.startDate)
      ? values.startDate.startOf("day").toISOString()
      : undefined,
    end_time: isDayjs(values.endDate) ? values.endDate.endOf("day").toISOString() : undefined,
  });

  useEffect(() => {
    if (isConsumer === false) {
      navigate("/");
    }
  }, [isConsumer, navigate]);

  return (
    <Stack spacing={6} flexGrow={1}>
      <ButtonRow>
        <Typography variant="h5">Deposit History</Typography>
      </ButtonRow>
      <ButtonRow flexWrap="wrap">
        <ButtonRow flexGrow={1}>
          <DateField
            control={control}
            name="startDate"
            maxDate={isDayjs(values.endDate) ? values.endDate : dayjs()}
            label="Select Range Start"
            variant="filled"
            clearable
            fullWidth
          />
          <DateField
            control={control}
            name="endDate"
            minDate={isDayjs(values.startDate) ? values.startDate : undefined}
            maxDate={dayjs()}
            label="Select Range End"
            variant="filled"
            clearable
            fullWidth
          />
        </ButtonRow>
      </ButtonRow>
      <FeesAndRevenueTable
        fields={[
          "time",
          "txidOrPaymentHash",
          "blockHeight",
          "revenueAmount",
          "usdAmount",
          "revenueType",
          "status",
        ]}
        items={revenueItems}
        isLoading={isLoading}
        noDataText="No history found in this date range."
      />
    </Stack>
  );
};
