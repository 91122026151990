import { Box } from "@mui/material";
import * as yup from "yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  ButtonRow,
  Form,
  FormButton,
  FormSubmit,
  IContract,
  InputField,
  Modal,
  UseModalProps,
  useAccount,
  useRenameContractCounterparty,
  useToast,
} from "@synota-io/synota-shared-ui";

export type EditContractSchema = { counterpartyName: string };

type Props = UseModalProps & {
  contract: IContract;
  onSuccess(): void;
};

const schema = yup.object({
  counterpartyName: yup.string().required("Counterparty name is required!"),
});

export const EditContractCounterpartyModal = ({ contract, open, onSuccess, onClose }: Props) => {
  const { jwt } = useAccount();
  const { success } = useToast();

  const { renameCounterparty, isPending } = useRenameContractCounterparty({
    onSuccess: () => {
      success("Contract counterparty name saved successfully.");
      onSuccess();
      onClose();
    },
  });

  const { handleSubmit, control } = useForm<EditContractSchema>({
    resolver: yupResolver(schema),
    values: { counterpartyName: contract.counterparty },
  });

  const onSubmit: SubmitHandler<EditContractSchema> = ({ counterpartyName }) => {
    renameCounterparty({
      jwt,
      contract_id: contract.id,
      counterparty_name: counterpartyName,
    });
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      fullWidth
      title="Rename counterparty"
      actions={
        <ButtonRow width="100%">
          <FormButton
            disabled={isPending}
            variant="text"
            color="error"
            size="small"
            onClick={() => onClose()}
          >
            Cancel
          </FormButton>

          <Box flexGrow={1} display="flex">
            <FormSubmit
              isLoading={isPending}
              fullWidth
              variant="contained"
              color="secondary"
              form="edit-contract-counteryparty-modal-form"
            />
          </Box>
        </ButtonRow>
      }
    >
      <Form id="edit-contract-counteryparty-modal-form" onSubmit={handleSubmit(onSubmit)}>
        <InputField
          disabled={isPending}
          autoComplete="off"
          fullWidth
          name="counterpartyName"
          control={control}
          header="Counterparty Name"
        />
      </Form>
    </Modal>
  );
};
