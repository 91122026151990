import { CardProps, Slide, SlideProps, styled } from "@mui/material";
import { FlexCard } from "@synota-io/synota-shared-ui";

export const StatCardElement = styled(FlexCard)(({ theme }) => ({
  flexGrow: 1,
  height: "100%",
  "& .MuiCardContent-root": {
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(6),
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(3, 2, 3, 3),
    },
  },
}));

export const StatCard = ({
  children,
  mountOnEnter,
  unmountOnExit,
  in: inProp = true,
  ...props
}: CardProps & Pick<SlideProps, "in" | "mountOnEnter" | "unmountOnExit">) => (
  <Slide in={inProp} mountOnEnter={mountOnEnter} unmountOnExit={unmountOnExit}>
    <StatCardElement {...props}>{children}</StatCardElement>
  </Slide>
);
